import React, { useEffect, useState, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MaterialReactTable } from 'material-react-table';
import { AuthContext } from '../../components/AuthContext/AuthContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { onCurrencyStatusOpen, onUpdateFavoritesByApi } from "../../Reducer/kpriceReducer";
import CurrencyChart from "../../components/MainChart/CurrencyChart";
import { formatValuePriceControl, formatValue3, formatValue4 } from '../../Lib/calculationFunctions';
import { checkMobileScreen } from '../../Lib/globalFunctions';
import withLoadingData from "../../Container/withLoadingData";
import isEqual from "react-fast-compare";
import Loading from "../../components/Global/Loading";
import { Dialog } from 'primereact/dialog';
import Tooltip from '@mui/material/Tooltip';

const SectionCurrencyStatus = ({ type, isProductsLoading, pageLink }) => {
    
    const { isAuthenticated, user, signOut } = useContext(AuthContext);

    const dispatch = useDispatch();

    useEffect(() => {//favorites verileri
        if(isAuthenticated){
            dispatch(onCurrencyStatusOpen());
        }
    }, [isAuthenticated]);

    const [displayFavoriteDialog, setDisplayFavoriteDialog] = useState(false);//favorite popup görünürlüğünü tutuyor
    const [favoriteValues, setFavoriteValues] = useState({});//favorite verileri
    const [lastDeletedFavoriteTimestamp, setLastDeletedFavoriteTimestamp] = useState(null);
    const [tableProducts, setTableProducts] = useState(null);//tablo verileri
    const [isMobile, setIsMobile] = useState(checkMobileScreen(650));

    const [columnVisibility, setColumnVisibility] = useState({
        quantity: false,
        favorite: false,
        graph24h: !isMobile,
    });

    const columnsProducts = useMemo(() => [//tablo bilgileri
        { accessorKey: 'name', header: 'Name', size: (checkMobileScreen(650) ? 80 : 80), Cell: ({ row }) => row.original.nameForTable },//row.original.nameForTable geçen yer newData içindeki changeForSorting değeri
        { accessorKey: 'graph24h', header: '24h Graph', size: (checkMobileScreen(650) ? 40 : 80), Cell: ({ row }) => row.original.graph24hForTable },
        { accessorKey: 'price', header: 'Price', size: (checkMobileScreen(650) ? 40 : 50), Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'change', header: 'Change', size: (checkMobileScreen(650) ? 40 : 50), Cell: ({ row }) => row.original.changeForTable },//row.original.changeForTable geçen yer newData içindeki changeForSorting değeri
        { accessorKey: 'volume', header: 'Volume', size: (checkMobileScreen(650) ? 40 : 50), Cell: ({ row }) => row.original.volumeForTable },
        { accessorKey: 'quantity', header: 'Quantity', show: false },
        { accessorKey: 'favorite', header: 'Favorite', show: false },
    ], [isMobile]);

    useEffect(() => {
        const handleResize = () => {
            const isMobile = checkMobileScreen(650);
            setIsMobile(isMobile);
            setColumnVisibility((prevVisibility) => ({
                ...prevVisibility,
                graph24h: !isMobile,
            }));
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const currencyStarCheck = (key, timestamp) => {
        setFavoriteValues(prevData => {
            return {
                ...prevData,
                [key]: { timestamp }
            };
        });
    }
    const currencyStarUncheck = (key) => {
        if(favoriteValues && favoriteValues[key]){
            setLastDeletedFavoriteTimestamp(favoriteValues[key].timestamp);
        }
        setFavoriteValues(prevData => {
            const newData = { ...prevData };
            delete newData[key];
            return newData;
        });
    }

    function adjustFavoritesData(data) {
        let adjustedData = { ...data };
    
        if (adjustedData && adjustedData.item) {
            const item = adjustedData.item;
            
            if (item.timestamp) {
                item.timestamp = Number(item.timestamp);
            }
        }
    
        return adjustedData;
    }
    
    const currencyStatusClickStar = async (event, key) => {
        if(isAuthenticated){
            let favoriteType = event.currentTarget.classList.contains('active') ? "remove" : "add";

            if(key != null && favoriteType != null){
                if(favoriteType == "add"){//işaretle
                    currencyStarCheck(key, 1);
                }
                else if(favoriteType == "remove"){//işareti kaldır
                    currencyStarUncheck(key);
                }

                let data = {
                    "product_id": key,
                    "type": favoriteType
                }

                const session = await Auth.currentSession();
                const jwtToken = session.getIdToken().getJwtToken();

                axios.post('https://api.kpricemarket.com/favorites', 
                    JSON.stringify(data),
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                )
                .then(function (response) {

                    let newKey = response.data.item.product_id;
                    let newTimestamp = response.data.item.timestamp ? Number(response.data.item.timestamp) : null;
                    const newFavorite = adjustFavoritesData(response.data);

                    if(response.status == "200"){
                        dispatch(onUpdateFavoritesByApi({ newFavorite }));
                        if(favoriteType == "add"){//timestamp değerini güncelliyoruz
                            currencyStarCheck(key, newTimestamp);
                        }
                    }
                    else{//hatalı durum için
                        if(favoriteType == "add"){//işareti kaldırıyoruz
                            currencyStarUncheck(key);
                        }
                        else if(favoriteType == "remove"){//tekrar işaretliyoruz
                            currencyStarCheck(key, lastDeletedFavoriteTimestamp);
                        }
                    }
                })
                .catch(function (error) {
                    toast.error(error.response.data.error);
                    //hatalı durum için
                    if(favoriteType == "add"){//işareti kaldırıyoruz
                        currencyStarUncheck(key);
                    }
                    else if(favoriteType == "remove"){//tekrar işaretliyoruz
                        currencyStarCheck(key, lastDeletedFavoriteTimestamp);
                    }
                });
            }
        }
        else{
            setDisplayFavoriteDialog(true);
        }
    }
    
    const productsData = useSelector((state) => state.kprice.products.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const favoritesData = useSelector((state) => state.kprice.favorites.data);

    const calcPrice = (price, show_places) => {
        const response = formatValuePriceControl(price, show_places);
        if(response){
            return formatValue3(price, show_places);
        }
        else{
            return formatValue4(price);
        }
    }

    const tableProducts_name = (key) => {
        let currenciesName = key.split("-")[0];
        return <div className="section-currencystatus-element-link chart">
            <div className="section-currencystatus-element-name">
                <i onClick={(event) => currencyStatusClickStar(event, key)} className={favoriteValues && favoriteValues[key] ? "fa-solid fa-star star-icon active" : "fa-solid fa-star star-icon"}></i>
                <Link to={`/${pageLink}?market=${key}`} className="innerlink">
                    <img src={currenciesData && currenciesData[currenciesName] ? currenciesData[currenciesName].picture_url : undefined} />
                </Link>
                <Link to={`/${pageLink}?market=${key}`} className="innerlink">
                    <div className="nametext">
                        <label>{currenciesData && currenciesData[currenciesName] ? currenciesData[currenciesName].currency_name : ""}</label>
                        <span><p>{key.split("-")[0]}</p>/{key.split("-")[1]}</span>
                    </div>
                </Link>
            </div>
        </div>;
    }

    const tableGraph24h_change = (key) => {
        let currenciesName = key.split("-")[0];
        return <div className="section-currencystatus-element-link chart">
            {!checkMobileScreen(650) && <Link to={`/${pageLink}?market=${key}`} className="innerlink chart">
                <div className="section-currencystatus-element-chart">
                    <div className="section-currencystatus-element-chart-inner">
                        <CurrencyChart product_id={key} chart_class={productsMiniTickerData && productsMiniTickerData[key] ? (productsMiniTickerData[key].percentage_difference_24h != null ? (productsMiniTickerData[key].percentage_difference_24h >= 0 ? "success" : "danger") : "danger") : "danger"} />
                    </div>
                </div>
            </Link>}
        </div>;
    }

    const tableProducts_price = (key) => {
        let show_places = productsData && productsData[key] && productsData[key].show_places ? productsData[key].show_places : "NaN";
        let currentPrice = productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].currentPrice ? productsMiniTickerData[key].currentPrice : null;
        let previousCurrentPrice = productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].previousCurrentPrice ? productsMiniTickerData[key].previousCurrentPrice : null;
        
        if(currentPrice && previousCurrentPrice && document.getElementById(`status-element-${key}`)){
            let currentCP = document.getElementById(`status-element-${key}`).dataset.current_price;
            let currentPCP = document.getElementById(`status-element-${key}`).dataset.previous_current_price;
            if(currentCP != currentPrice || currentPCP != previousCurrentPrice){
                if(currentPrice > previousCurrentPrice){
                    document.getElementById(`status-element-${key}`).classList.remove("success", "danger");
                    setTimeout(() => {
                        if(document.getElementById(`status-element-${key}`)){
                            document.getElementById(`status-element-${key}`).classList.add("success");
                        }
                    }, 10);
                }
                else if(currentPrice < previousCurrentPrice){
                    document.getElementById(`status-element-${key}`).classList.remove("success", "danger");
                    setTimeout(() => {
                        if(document.getElementById(`status-element-${key}`)){
                            document.getElementById(`status-element-${key}`).classList.add("danger");
                        }
                    }, 10);
                }
                document.getElementById(`status-element-${key}`).dataset.current_price = currentPrice;
                document.getElementById(`status-element-${key}`).dataset.previous_current_price = previousCurrentPrice;
            }
        }
        return <Link to={`/${pageLink}?market=${key}`} className="section-currencystatus-element-link">
            <div id={`status-element-${key}`} className="section-currencystatus-element-price riseAndFallValueAnimation" data-current_price="" data-previous_current_price="">
                {currentPrice ? calcPrice(currentPrice, show_places) : "NaN"}
            </div>
        </Link>;
    }

    const tableProducts_change = (key) => {
        return <Link to={`/${pageLink}?market=${key}`} className="section-currencystatus-element-link">
            <div className="section-currencystatus-element-change">
                <div className={productsMiniTickerData && productsMiniTickerData[key] ? (productsMiniTickerData[key].percentage_difference_24h != "" && productsMiniTickerData[key].percentage_difference_24h != null && productsMiniTickerData[key].percentage_difference_24h != undefined ? (productsMiniTickerData[key].percentage_difference_24h >= 0 ? "success" : "danger") : "danger") : "danger"}>{productsMiniTickerData && productsMiniTickerData[key] ? (productsMiniTickerData[key].percentage_difference_24h != "" && productsMiniTickerData[key].percentage_difference_24h != null && productsMiniTickerData[key].percentage_difference_24h != undefined ? formatValue3(productsMiniTickerData[key].percentage_difference_24h, 2) : "NaN") : "NaN"}%</div>
            </div>
        </Link>;
    }

    const tableProducts_volume = (key) => {
        const currentVolume = productsMiniTickerData[key].q_24h != "" && productsMiniTickerData[key].q_24h != null && productsMiniTickerData[key].q_24h != undefined ? formatValue3(productsMiniTickerData[key].q_24h, 2) : "NaN";
        return <Link to={`/${pageLink}?market=${key}`} className="section-currencystatus-element-link">
            <Tooltip title={currentVolume} placement="top">
                <div className="section-currencystatus-element-volume">
                    {productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].q_24h != null ? currentVolume : "NaN"}
                </div>
            </Tooltip>
        </Link>;
    }

    useEffect(() => {
        if(favoritesData){
            setFavoriteValues(favoritesData);
        }
    }, [favoritesData]);

    useEffect(() => {
        const productKeys = productsMiniTickerData ? Object.keys(productsMiniTickerData) : [];

        const processData = () => {
            const newData = [];
            
            productKeys.forEach((key) => {
                let productStatusSpotActive = productsData && productsData[key] && productsData[key].status_spot && productsData[key].status_spot == "active" ? true : false;
                if(productStatusSpotActive){
                    newData.push({
                        name: currenciesData && currenciesData[key.split("-")[0]] ? currenciesData[key.split("-")[0]].currency_name : "",
                        nameForTable: tableProducts_name(key),
                        graph24h: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].percentage_difference_24h != null ? productsMiniTickerData[key].percentage_difference_24h : 0,
                        graph24hForTable: tableGraph24h_change(key),
                        price: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].currentPrice ? productsMiniTickerData[key].currentPrice : 0,
                        priceForTable: tableProducts_price(key),
                        change: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].percentage_difference_24h != null ? productsMiniTickerData[key].percentage_difference_24h : 0,
                        changeForTable: tableProducts_change(key),
                        volume: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].q_24h != null ? productsMiniTickerData[key].q_24h : 0,
                        volumeForTable: tableProducts_volume(key),
                        quantity: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].q_24h != null ? productsMiniTickerData[key].q_24h : 0,
                        favorite: favoriteValues && favoriteValues[key] ? favoriteValues[key].timestamp : null
                    });
                }
            });
            
            return type == "favorite" ? newData.filter(row => row.favorite != null) : newData;
        };

        setTableProducts(processData());
    }, [productsMiniTickerData, currenciesData, favoriteValues]);
    
    return(
        <div id="section-currencystatus" className="section-currencystatus">
            <Dialog header="Favorite" visible={displayFavoriteDialog} draggable={false} resizable={false} className="section-currencystatus-dialog" onHide={() => setDisplayFavoriteDialog(false)}>
                <label>You must login or register to add to favorites.</label>
                <div className="buttons">
                    <Link to="/login">Login</Link>
                    <Link to="/register">Register</Link>
                </div>
            </Dialog>
            {isProductsLoading != false || tableProducts == null ? (//undefined ya da true geldiyse loading gösteriliyor, false ise grafik
                <div className="currencyStatusLoading"><Loading /></div>
            ) : (
                <MaterialReactTable 
                    columns={columnsProducts} 
                    data={tableProducts} 
                    enablePagination={false}
                    enableRowVirtualization={true}
                    enableColumnActions={false}
                    state={{
                        columnVisibility,
                    }}
                    initialState={{
                        sorting: type == "hot" ? [{id: 'quantity', desc: true}] : (type == "positive" ? [{id: 'change', desc: true}] : (type == "favorite" ? [{id: 'favorite', desc: true}] : (type == "volume" ? [{id: 'volume', desc: true}] : [{id: 'change', desc: false}])))
                    }} 
                    className="section-currencystatus-table" 
                />
            )}
        </div>
    )
    
}

export default withLoadingData()(React.memo(SectionCurrencyStatus, isEqual));