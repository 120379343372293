import React, { useEffect, useState, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MaterialReactTable } from 'material-react-table';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { AuthContext } from '../../components/AuthContext/AuthContext';
import { onCurrencyStatusOpen, onUpdateFavoritesByApi } from "../../Reducer/kpriceReducer";
import { formatValuePriceControl, formatValue3, formatValue4 } from '../../Lib/calculationFunctions';
import withLoadingData from "../../Container/withLoadingData";
import isEqual from "react-fast-compare";
import Loading from "../../components/Global/Loading";
import { Dialog } from 'primereact/dialog';

const SectionTradeCryptocurrencyList = ({ isProductsLoading, pageLink }) => {

    const { isAuthenticated, user, signOut } = useContext(AuthContext);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {//favorites verileri
        if(isAuthenticated){
            dispatch(onCurrencyStatusOpen());
        }
    }, [isAuthenticated]);

    const [displayFavoriteDialog, setDisplayFavoriteDialog] = useState(false);//favorite popup görünürlüğünü tutuyor
    const [searchValue, setSearchValue] = useState("");//arama input verisi
    const [tabValue, setTabValue] = useState("Top Gainers");//sekme verisi
    const [favoriteValues, setFavoriteValues] = useState({});//favorite verileri
    const [lastDeletedFavoriteTimestamp, setLastDeletedFavoriteTimestamp] = useState(null);
    const [tableProducts, setTableProducts] = useState([]);//tablo verileri
    const [sorting, setSorting] = useState([{ id: 'change', desc: true }]);

    useEffect(() => {
        if (tabValue === "Hot") {
            setSorting([{ id: 'quantity', desc: true }]);
        } else if (tabValue === "Top Gainers") {
            setSorting([{ id: 'change', desc: true }]);
        } else if (tabValue === "Star") {
            setSorting([{ id: 'favorite', desc: true }]);
        } else {
            setSorting([{ id: 'change', desc: false }]);
        }
    }, [tabValue]);

    const columnsProducts = useMemo(() => [//tablo bilgileri
        { accessorKey: 'pairs', header: 'Pairs', size: 50, Cell: ({ row }) => row.original.pairsForTable },
        { accessorKey: 'lastPrice', header: 'Last Price', size: 50, Cell: ({ row }) => row.original.lastPriceForTable },
        { accessorKey: 'change', header: 'Change', size: 40, Cell: ({ row }) => row.original.changeForTable },
        { accessorKey: 'filter', header: 'Filter', show: false },
        { accessorKey: 'favorite', header: 'Favorite', show: false },
        { accessorKey: 'changeForSearch', header: 'Change For Search', show: false },
        { accessorKey: 'quantity', header: 'Quantity', show: false },
    ], []);

    const currencyStarCheck = (key, timestamp) => {
        setFavoriteValues(prevData => {
            return {
                ...prevData,
                [key]: { timestamp }
            };
        });
    }
    const currencyStarUncheck = (key) => {
        if(favoriteValues && favoriteValues[key]){
            setLastDeletedFavoriteTimestamp(favoriteValues[key].timestamp);
        }
        setFavoriteValues(prevData => {
            const newData = { ...prevData };
            delete newData[key];
            return newData;
        });
    }

    function adjustFavoritesData(data) {
        let adjustedData = { ...data };
    
        if (adjustedData && adjustedData.item) {
            const item = adjustedData.item;
            
            if (item.timestamp) {
                item.timestamp = Number(item.timestamp);
            }
        }
    
        return adjustedData;
    }
    
    const currencyStatusClickStar = async (event, key) => {
        if(isAuthenticated){
            let favoriteType = event.currentTarget.classList.contains('active') ? "remove" : "add";

            if(key != null && favoriteType != null){

                if(favoriteType == "add"){//işaretle
                    currencyStarCheck(key, 1);
                }
                else if(favoriteType == "remove"){//işareti kaldır
                    currencyStarUncheck(key);
                }

                let data = {
                    "product_id": key,
                    "type": favoriteType
                }

                const session = await Auth.currentSession();
                const jwtToken = session.getIdToken().getJwtToken();

                axios.post('https://api.kpricemarket.com/favorites', 
                    JSON.stringify(data),
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                )
                .then(function (response) {

                    let newKey = response.data.item.product_id;
                    let newTimestamp = response.data.item.timestamp ? Number(response.data.item.timestamp) : null;
                    const newFavorite = adjustFavoritesData(response.data);

                    if(response.status == "200"){
                        dispatch(onUpdateFavoritesByApi({ newFavorite }));
                        if(favoriteType == "add"){//timestamp değerini güncelliyoruz
                            currencyStarCheck(key, newTimestamp);
                        }
                    }
                    else{//hatalı durum için
                        if(favoriteType == "add"){//işareti kaldırıyoruz
                            currencyStarUncheck(key);
                        }
                        else if(favoriteType == "remove"){//tekrar işaretliyoruz
                            currencyStarCheck(key, lastDeletedFavoriteTimestamp);
                        }
                    }
                })
                .catch(function (error) {
                    toast.error(error.response.data.error);
                    //hatalı durum için
                    if(favoriteType == "add"){//işareti kaldırıyoruz
                        currencyStarUncheck(key);
                    }
                    else if(favoriteType == "remove"){//tekrar işaretliyoruz
                        currencyStarCheck(key, lastDeletedFavoriteTimestamp);
                    }
                });

            }
        }
        else{
            setDisplayFavoriteDialog(true);
        }
    }

    const linkControl = (e, key) => {
        if(query.get("market") == key){
            e.preventDefault();
        }
    };
    
    const productsData = useSelector((state) => state.kprice.products.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const favoritesData = useSelector((state) => state.kprice.favorites.data);

    const calcPrice = (price, show_places) => {
        const response = formatValuePriceControl(price, show_places);
        if(response){
            return formatValue3(price, show_places);
        }
        else{
            return formatValue4(price);
        }
    }

    const tableProducts_name = (key) => {
        return <div className="section-tradecryptocurrencylist-element">
            <i onClick={(event) => currencyStatusClickStar(event, key)} className={favoriteValues && favoriteValues[key] ? "fa-solid fa-star active" : "fa-solid fa-star"}></i>
            <Link to={`/${pageLink}?market=${key}`} onClick={(e) => linkControl(e, key)}>{key.split("-")[0]}/{key.split("-")[1]}</Link>
        </div>;
    }

    const tableProducts_price = (key) => {
        let show_places = productsData && productsData[key] && productsData[key].show_places ? productsData[key].show_places : "NaN";
        let currentPrice = productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].currentPrice ? productsMiniTickerData[key].currentPrice : null;
        let previousCurrentPrice = productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].previousCurrentPrice ? productsMiniTickerData[key].previousCurrentPrice : null;
        
        if(currentPrice && previousCurrentPrice && document.getElementById(`status-element-${key}`)){
            let currentCP = document.getElementById(`status-element-${key}`).dataset.current_price;
            let currentPCP = document.getElementById(`status-element-${key}`).dataset.previous_current_price;
            if(currentCP != currentPrice || currentPCP != previousCurrentPrice){
                if(currentPrice > previousCurrentPrice){
                    document.getElementById(`status-element-${key}`).classList.remove("success", "danger");
                    setTimeout(() => {
                        if(document.getElementById(`status-element-${key}`)){
                            document.getElementById(`status-element-${key}`).classList.add("success");
                        }
                    }, 10);
                }
                else if(currentPrice < previousCurrentPrice){
                    document.getElementById(`status-element-${key}`).classList.remove("success", "danger");
                    setTimeout(() => {
                        if(document.getElementById(`status-element-${key}`)){
                            document.getElementById(`status-element-${key}`).classList.add("danger");
                        }
                    }, 10);
                }
                document.getElementById(`status-element-${key}`).dataset.current_price = currentPrice;
                document.getElementById(`status-element-${key}`).dataset.previous_current_price = previousCurrentPrice;
            }
        }
        return <Link to={`/${pageLink}?market=${key}`} id={`status-element-${key}`} className="section-tradecryptocurrencylist-element price" data-current_price="" data-previous_current_price="" onClick={(e) => linkControl(e, key)}>
            {currentPrice ? calcPrice(currentPrice, show_places) : "NaN"}
        </Link>;
    }

    const tableProducts_change = (key) => {
        return <Link to={`/${pageLink}?market=${key}`} className="section-tradecryptocurrencylist-element" onClick={(e) => linkControl(e, key)}>
            <div className={productsMiniTickerData && productsMiniTickerData[key] ? (productsMiniTickerData[key].percentage_difference_24h != null ? (productsMiniTickerData[key].percentage_difference_24h >= 0 ? "success" : "danger") : "danger") : "danger"}>{productsMiniTickerData && productsMiniTickerData[key] ? (productsMiniTickerData[key].percentage_difference_24h != "" && productsMiniTickerData[key].percentage_difference_24h != null && productsMiniTickerData[key].percentage_difference_24h != undefined ? formatValue3(productsMiniTickerData[key].percentage_difference_24h, 2) : "NaN") : "NaN"}%</div>
        </Link>;
    }

    useEffect(() => {
        if(favoritesData){
            setFavoriteValues(favoritesData);
        }
    }, [favoritesData]);

    useEffect(() => {//tablo verilerinin oluşturulması
        const productKeys = productsMiniTickerData ? Object.keys(productsMiniTickerData) : [];

        const processData = () => {
            let newData = [];
            
            productKeys.forEach((key) => {
                let productStatusFutureActive = productsData && productsData[key] && productsData[key].status_future && productsData[key].status_future == "active" ? true : false;
                let productStatusSpotActive = productsData && productsData[key] && productsData[key].status_spot && productsData[key].status_spot == "active" ? true : false;
                if((pageLink == "future" && productStatusFutureActive) || (pageLink == "trade" && productStatusSpotActive) || (pageLink != "future" && pageLink != "trade")){
                    newData.push({
                        pairs: `${key.split("-")[0]}/${key.split("-")[1]}`,
                        pairsForTable: tableProducts_name(key),
                        lastPrice: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].currentPrice ? productsMiniTickerData[key].currentPrice : 0,
                        lastPriceForTable: tableProducts_price(key),
                        change: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].percentage_difference_24h != null ? productsMiniTickerData[key].percentage_difference_24h : 0,
                        changeForTable: tableProducts_change(key),
                        filter: key.split("-")[1],
                        favorite: favoriteValues && favoriteValues[key] ? favoriteValues[key].timestamp : null,
                        quantity: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].q_24h != null ? productsMiniTickerData[key].q_24h : 0,
                        changeForSearch: `${Number(productsMiniTickerData[key].percentage_difference_24h)}%`
                    });
                }
            });

            // İlk filtreleme: tabValue'ye göre
            if (tabValue === "Star") {
                newData = newData.filter(row => row.favorite != null);
            }

            // İkinci filtreleme: searchValue'ye göre
            if (searchValue) {
                newData = newData.filter(row => 
                    [row.pairs, row.lastPrice, row.changeForSearch].some(value => 
                        value && value.toString().toLowerCase().includes(searchValue.toLowerCase())
                    )
                );
            }
            
            return newData;
        };

        setTableProducts(processData());
    }, [productsData, productsMiniTickerData, currenciesData, favoriteValues, tabValue, searchValue]);

    useEffect(() => {//sekme verisi değiştikçe sönüp yanma animasyonu tetikleniyor
        const tbodyElement = document.querySelector('.section-tradecryptocurrencylist-table tbody');
        if(tbodyElement){
            tbodyElement.style.animation = "none";
            let tempForAnimation = tbodyElement.offsetHeight;
            tbodyElement.style.animation = "open-animation 1s";
        }
    }, [tabValue]);

    return(
        <div className="allsections allsections-border section-tradecryptocurrencylist">
            <Dialog header="Favorite" visible={displayFavoriteDialog} draggable={false} resizable={false} className="section-tradecryptocurrencylist-dialog" onHide={() => setDisplayFavoriteDialog(false)}>
                <label>You must login or register to add to favorites.</label>
                <div className="buttons">
                    <Link to="/login">Login</Link>
                    <Link to="/register">Register</Link>
                </div>
            </Dialog>
            <div className="section-tradecryptocurrencylist-search">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input type="search" value={searchValue} placeholder="Search" onChange={(event) => setSearchValue(event.target.value)}/>
            </div>
            <div className="section-tradecryptocurrencylist-options">
                <i className={tabValue == "Star" ? "fa-solid fa-star active" : "fa-solid fa-star"} onClick={() => setTabValue("Star")}></i>
                <span className={tabValue == "Hot" ? "active" : undefined} onClick={() => setTabValue("Hot")}>Hot</span>
                <span className={tabValue == "Top Gainers" ? "active" : undefined} onClick={() => setTabValue("Top Gainers")}>Top Gainers</span>
                <span className={tabValue == "Top Losers" ? "active" : undefined} onClick={() => setTabValue("Top Losers")}>Top Losers</span>
            </div>
            {isProductsLoading != false ? (//undefined ya da true geldiyse loading gösteriliyor, false ise grafik
                <div><Loading /></div>
            ) : (
                <div className="section-tradecryptocurrencylist-table">
                    <MaterialReactTable 
                        columns={columnsProducts} 
                        data={tableProducts} 
                        enablePagination={false}
                        enableRowVirtualization={true}
                        state={{
                            columnVisibility: { filter: false, favorite: false, changeForSearch: false, quantity: false },
                            sorting: sorting
                        }}
                        onSortingChange={(newSorting) => setSorting(newSorting)}
                    />
                </div>
            )}
        </div>
    )
    
}

export default withLoadingData()(React.memo(SectionTradeCryptocurrencyList, isEqual));