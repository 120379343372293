import { productsDataUtils } from '../../Lib/utils.js';
import { subscriptions, subscriptionConfig } from '../../Lib/asyncUtil.js';
import { startFirebaseSubscription, stopFirebaseSubscription } from '../../Lib/firebase';

import { API, graphqlOperation, Auth } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

import store from '../../store.js';

import moment from 'moment';

const channelToSubscription = new Map();

const CONNECT_CANDLE_PRODUCT_UPDATED = "CONNECT_CANDLE_PRODUCT_UPDATED";
const CONNECT_CANDLE_PRODUCT_UPDATED_SUCCESS = "CONNECT_CANDLE_PRODUCT_UPDATED_SUCCESS";
const CONNECT_CANDLE_PRODUCT_UPDATED_ERROR = "CONNECT_CANDLE_PRODUCT_UPDATED_ERROR";

const handleProductUpdate = (data) => {
    const product_id = data.product_id;
	const last_trades_obj = data.last_trades;
	const last_trades = [];
	last_trades.push(last_trades_obj);
	
	const channelString = product_id;
	const subscriptionItem = channelToSubscription.get(channelString);

	if (subscriptionItem === undefined) {
		return;
	}

	subscriptionItem.handlers.forEach(handler => {
		for (let i = 0; i < last_trades.length; i++) {
			const tradePrice = last_trades[i].p != undefined ? last_trades[i].p : null;
			const tradeTime = last_trades[i].MT != undefined ? last_trades[i].MT : null;

			const volume = last_trades[i].v != undefined ? last_trades[i].v : null;

			const resolution = handler.resolution;
			let lastBar = handler.lastBar;
			

			const nextBarTime = getNextBarTime(resolution, lastBar.time);



			if (tradeTime >= nextBarTime) {
                lastBar = {
                    time: nextBarTime,
                    open: tradePrice,
                    high: tradePrice,
                    low: tradePrice,
                    close: tradePrice,
                    volume: volume,
                    lastMT: tradeTime,
                };
			} 
			else{
                // Mevcut barı güncelle
                lastBar = {
                    ...lastBar,
					open: tradeTime && tradePrice && tradeTime < lastBar.firstMT && tradeTime >= lastBar.time ? tradePrice : lastBar.open,
                    high: tradePrice ? Math.max(lastBar.high, tradePrice) : lastBar.high,
                    low: tradePrice ? Math.min(lastBar.low, tradePrice) : lastBar.low,
                    close: tradeTime && tradePrice && tradeTime >= lastBar.lastMT ? tradePrice : lastBar.close,
                    volume: volume ? (lastBar.volume + volume) : lastBar.volume,
                    lastMT: tradeTime ? Math.max(lastBar.lastMT, tradeTime) : lastBar.lastMT,
					firstMT: tradeTime && tradeTime < lastBar.firstMT && tradeTime >= lastBar.time ? tradeTime : lastBar.firstMT,
                };
            }
			
			handler.lastBar = lastBar;
			handler.callback(lastBar);
		}
    });



}

const getNextBarTime = (resolution, timestamp) => {
	const momentObj = moment.utc(timestamp); 
  
	switch (resolution) {
	  case "1":
	  case "5":
	  case "15":
	  case "30":
		const minutes = parseInt(resolution, 10);
		return momentObj.add(minutes, 'minutes').valueOf();
	  case "60":
	  case "120":
	  case "240":
	  case "360":
	  case "720":
		const hours = parseInt(resolution) / 60;
		return momentObj.add(hours, 'hours').valueOf();
	  case "1D":
		return momentObj.add(1, 'days').startOf('day').valueOf();
	  case "1W":
		return momentObj.add(1, 'weeks').startOf('isoWeek').valueOf();
	  case "1M":
		return momentObj.add(1, 'months').startOf('month').valueOf();
	  default:
		return undefined;
	}
  };

export async function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscriberUID,
	onResetCacheNeededCallback,
	lastBar,
) {
	const product_id = symbolInfo.product_id;
	const handler = {
		id: subscriberUID,
		callback: onRealtimeCallback,
		resolution,
		lastBar,
	};
	let subscriptionItem = channelToSubscription.get(product_id);
	if (subscriptionItem) {
		// Already subscribed to the channel, use the existing subscription
		subscriptionItem.handlers.push(handler);
		return;
	}


	const type = CONNECT_CANDLE_PRODUCT_UPDATED;
	const SUCCESS = `${type}_SUCCESS`;
	const ERROR = `${type}_ERROR`;

	startFirebaseSubscription(type, product_id, (error, updatedData) => {
		if (error) {
		  	console.log(`${type} aboneliğinde hata:`, error);
		  	return;
		}

		handleProductUpdate(updatedData);

		const state = store.getState();
		store.dispatch({ type: SUCCESS, payload: productsDataUtils.update(updatedData, state) });
	});

	subscriptionItem = {
		subscriberUID,
		handlers: [handler],
	};
	channelToSubscription.set(product_id, subscriptionItem);
	//console.log(product_id,'İÇİN YENİ BİR ABONELİK BAŞLATILDI');
}

export function unsubscribeFromStream(subscriberUID) {
	// Find a subscription with id === subscriberUID
	for (const product_id of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(product_id);
		const handlerIndex = subscriptionItem.handlers
			.findIndex(handler => handler.id === subscriberUID);

		if (handlerIndex !== -1) {
			// Remove from handlers
			subscriptionItem.handlers.splice(handlerIndex, 1);

			if (subscriptionItem.handlers.length === 0) {
				stopFirebaseSubscription("CONNECT_CANDLE_PRODUCT_UPDATED", product_id);
				channelToSubscription.delete(product_id);
				break;
			}
		}
	}
}
