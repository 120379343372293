import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { AuthContext } from '../../components/AuthContext/AuthContext';
import axios from 'axios';
import 'material-react-toastify/dist/ReactToastify.css';
import { Auth } from 'aws-amplify';
import Decimal from 'decimal.js';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Link } from 'react-router-dom';
import Loading from "../../components/Global/Loading";
import { formatValue3, getAvailableCountBuy, getAvailableCountSell, getValueWithShowPlaces, getMinAndStepWithShowPlaces, formatNumberTrimTrailingZeros, valueControlWithDigitLimit } from '../../Lib/calculationFunctions';

const CreateForm = ({ onOrderDataReceived, selectedProduct, productsData, productsFastData, currenciesData, walletPrice, walletAmount, isAuthenticated, formTab, formType }) => {
    let productInfo = selectedProduct ? selectedProduct.split("-") : [];//ürün isim bilgileri tutulacak 0: amount, 1: price, 2: product_id
    if(selectedProduct){
        productInfo.push(selectedProduct)
    }

    const userInfo = useSelector((state) => state.kprice.userInfo.data);
    const currentPrice = productsFastData && productsFastData[selectedProduct] ? productsFastData[selectedProduct].currentPrice : "NaN";
    const commission_rate = productsData && productsData[selectedProduct] ? productsData[selectedProduct].spot_commission_rate : "0";
    const inputInfo_step = productsData && productsData[selectedProduct] ? productsData[selectedProduct].min_order_precision : "0";
    const inputInfo_min = productsData && productsData[selectedProduct] ? productsData[selectedProduct].min_order_count : "0";
    const inputInfo_askingPricePlaces = productsData && productsData[selectedProduct] ? productsData[selectedProduct].max_order_asking_price_places : "0";
    const inputInfo_countPlaces = productsData && productsData[selectedProduct] ? productsData[selectedProduct].max_order_count_places : "0";
    const inputInfo_maxBeforePlaces = productsData && productsData[selectedProduct] ? productsData[selectedProduct].max_before_places : null;
    const showPlacesAmount = selectedProduct && currenciesData && currenciesData[selectedProduct.split("-")[0]] && currenciesData[selectedProduct.split("-")[0]].show_places ? currenciesData[selectedProduct.split("-")[0]].show_places : null;
    const showPlacesPrice = selectedProduct && currenciesData && currenciesData[selectedProduct.split("-")[1]] && currenciesData[selectedProduct.split("-")[1]].show_places ? currenciesData[selectedProduct.split("-")[1]].show_places : null;
    
    const form = useRef(null);
    const inputPrice = useRef(null);
    const inputTotal = useRef(null);
    const inputAmount = useRef(null);
    const inputStopLoss = useRef(null);
    const inputTakeProfit = useRef(null);
    const inputPercentage = useRef(null);

    const [availablePrice, setAvailablePrice] = useState(0);
    const [availableAmount, setAvailableAmount] = useState(0);
    const [volumePrice, setVolumePrice] = useState(0);
    const [volumeAmount, setVolumeAmount] = useState(0);
    const [fee, setFee] = useState(0);
    const [percentageSliderValue, setPercentageSliderValue] = useState(null);
    const [lastUpdater, setLastUpdater] = useState(null);
    
    const [currentPriceForLimitAskingPrice, setCurrentPriceForLimitAskingPrice] = useState(false);
    const [firstRunForPageOpening, setFirstRunForPageOpening] = useState(false);

    const [inputStopLossActive, setInputStopLossActive] = useState(false);
    const [inputTakeProfitActive, setInputTakeProfitActive] = useState(false);

    const [submitTradeLoading, setSubmitTradeLoading] = useState(false);

    const clearalldanger = () => {//tüm formlardaki hata belirten kırmızı çerçeveleri temizlemek için
        let elements1 = document.getElementsByClassName("section-tradebuysell")[0].getElementsByTagName("input");
        for (let i = 0; i < elements1.length; i++) {
            elements1[i].classList.remove("danger");
        }

        let elements2 = document.getElementsByClassName("section-tradebuysell")[0].getElementsByClassName("section-tradebuysell-inner-input-label");
        for (let i = 0; i < elements2.length; i++) {
            elements2[i].classList.remove("danger");
        }

        let elements3 = document.getElementsByClassName("section-tradebuysell-inner-results-available");
        for (let i = 0; i < elements3.length; i++) {
            elements3[i].classList.remove("danger");
        }
    }

    const clearalldata = () => {//post işlemi sonrası form içindeki bilgileri temizlemek için
        inputPercentage.current = null;
        setPercentageSliderValue(0);

        inputPrice.current.value = "";
        inputAmount.current.value = "";
        inputTotal.current.value = "";

        if(formType == "buy"){
            inputStopLoss.current.value = "";
            inputTakeProfit.current.value = "";
            setInputStopLossActive(false);
            setInputTakeProfitActive(false);
        }

        //result değerleri sıfırlanıyor
        setVolumePrice(0);
        setVolumeAmount(0);
        setFee(0);

        setCurrentPriceForLimitAskingPrice(false);
    }
    
    const setVolumeAndFee = () => {
        let formvalid = true;
        let controlInputs = formTab == "limit" ? [inputPrice, inputAmount] : [inputAmount];
        
        controlInputs.forEach(ref => {//hata verilecek durumlar kontrol ediliyor
            if(ref.current.value == "" || ref.current.value == "0" || Number(ref.current.value) < Number(ref.current.min) || !valueControlWithDigitLimit(ref.current.value, inputInfo_maxBeforePlaces)){
                formvalid = false;
            }
        });

        if(formvalid){
            let value_asking_price = formTab == "limit" ? Number(inputPrice.current.value) : Number(currentPrice);
            let value_amount = Number(inputAmount.current.value);
            
            //volume
            if(formType == "buy"){
                let effective_processing_price = new Decimal(value_asking_price).mul(new Decimal(1).plus(commission_rate));
                setVolumeAmount(value_amount);
                setVolumePrice(new Decimal(value_amount).mul(effective_processing_price));
            }
            else if(formType == "sell"){
                let effective_processing_price = new Decimal(value_asking_price).mul(new Decimal(1).minus(commission_rate));
                setVolumeAmount(value_amount);
                setVolumePrice(new Decimal(value_amount).mul(effective_processing_price));
            }
            
            //fee
            const currentFee = new Decimal(value_asking_price).mul(new Decimal(commission_rate)).mul(new Decimal(value_amount));
            setFee(formatValue3(currentFee, showPlacesPrice));
        }
    }
    
    const formControl = async (formpost) => {//form post kontrolleri ve işlemleri
        setSubmitTradeLoading(true);
        clearalldanger();
        changeDisabledSubmitButtons(true);
        
        let formvalid = 1;//form post edilebilir mi, geçerli mi
        let errormessages = [];
        let controlInputs = [];
        
        if(formTab == "limit"){//kontrol edilecek input'lar belirleniyor
            if(formType == "buy"){
                controlInputs = [inputPrice, inputAmount, inputTotal, inputStopLoss, inputTakeProfit];
            }
            else if(formType == "sell"){
                controlInputs = [inputPrice, inputAmount, inputTotal];
            }
        }
        else if(formTab == "market"){
            if(formType == "buy"){
                controlInputs = [inputAmount, inputTotal, inputStopLoss, inputTakeProfit];
            }
            else if(formType == "sell"){
                controlInputs = [inputAmount, inputTotal];
            }
        }

        controlInputs.forEach(ref => {//bilgileri olması gerektiği gibi düzenleme kontrolleri
            if(ref.current.value == "00"){
                ref.current.value = "0";
            }
            else if(Number(ref.current.value) < 0 || ref.current.value.includes("-") || ref.current.value.includes("e")){
                ref.current.value = "";
            }
            else{
                if(ref.current.value[1] != undefined && ref.current.value[0] == "0" && ref.current.value[1] != "." && ref.current.value[1] != ","){
                    ref.current.value = ref.current.value.slice(1);
                }
                if(ref.current.value.includes(".") || ref.current.value.includes(",")){
                    if(ref.current.name != undefined && ref.current.name == "count"){
                        ref.current.value = getValueWithShowPlaces(inputInfo_countPlaces, ref.current.value);
                    }
                    else{
                        ref.current.value = getValueWithShowPlaces(inputInfo_askingPricePlaces, ref.current.value);
                    }
                }
            }
        });
        
        if(formTab == "limit"){//hata vermesi gereken input'lar belirleniyor
            controlInputs = [inputPrice, inputAmount];
        }
        else if(formTab == "market"){
            controlInputs = [inputAmount];
        }
        
        controlInputs.forEach(ref => {//hata verilecek durumlar kontrol ediliyor
            if(ref.current.value == "" || ref.current.value == "0"){
                if(ref.current.name == "asking_price" && formType == "buy"){//buy kısmında price girme alanı boş ise yüzde seçeneklerindeki işaret temizleniyor
                    inputPercentage.current = null;
                    setPercentageSliderValue(0);
                    inputTotal.current.value = "";
                }

                formvalid = 0;
                ref.current.classList.add("danger");
                errormessages.push("Please enter value for " + ref.current.dataset.name_info + "!");
            }
            else if(Number(ref.current.value) < Number(ref.current.min)){
                formvalid = 0;
                ref.current.classList.add("danger");
                errormessages.push("The value entered for " + ref.current.dataset.name_info + " must be greater than or equal to " + ref.current.min + "!");
            }
            else if(!valueControlWithDigitLimit(ref.current.value, inputInfo_maxBeforePlaces)){
                formvalid = 0;
                ref.current.classList.add("danger");
                errormessages.push("The integer part of the value you enter for " + ref.current.dataset.name_info + " cannot exceed " + inputInfo_maxBeforePlaces + " digits!");
            }
        });

        if(formType == "buy"){//stop loss ve take profit değerlerinin price değerine göre uygunluk kontrolü
            const priceValue = formTab == "limit" ? inputPrice.current.value : currentPrice;

            if(inputStopLoss.current.value != "" && inputStopLoss.current.value != "0" && !valueControlWithDigitLimit(inputStopLoss.current.value, inputInfo_maxBeforePlaces)){
                formvalid = 0;
                inputStopLoss.current.classList.add("danger");
                errormessages.push("The integer part of the value you enter for Stop Loss cannot exceed " + inputInfo_maxBeforePlaces + " digits!");
            }

            if(inputTakeProfit.current.value != "" && inputTakeProfit.current.value != "0" && !valueControlWithDigitLimit(inputTakeProfit.current.value, inputInfo_maxBeforePlaces)){
                formvalid = 0;
                inputTakeProfit.current.classList.add("danger");
                errormessages.push("The integer part of the value you enter for Take Profit cannot exceed " + inputInfo_maxBeforePlaces + " digits!");
            }

            if(priceValue != "" && inputStopLoss.current.value != ""){
                if(Number(priceValue) <= Number(inputStopLoss.current.value)){
                    formvalid = 0;
                    inputPrice.current.classList.add("danger");
                    inputStopLoss.current.classList.add("danger");
                    errormessages.push("Stop Loss value must be less than Price!");
                }
                else if(0 >= Number(inputStopLoss.current.value)){
                    formvalid = 0;
                    inputStopLoss.current.classList.add("danger");
                    errormessages.push("Stop Loss value must be bigger than 0!");
                }
            }

            if(priceValue != "" && inputTakeProfit.current.value != ""){
                if(Number(priceValue) >= Number(inputTakeProfit.current.value)){
                    formvalid = 0;
                    inputPrice.current.classList.add("danger");
                    inputTakeProfit.current.classList.add("danger");
                    errormessages.push("Take Profit value must be bigger than Price!");
                }
                else if(0 >= Number(inputTakeProfit.current.value)){
                    formvalid = 0;
                    inputTakeProfit.current.classList.add("danger");
                    errormessages.push("Take Profit value must be bigger than 0!");
                }
            }
        }

        if(formvalid == 1){//input girdileri uygunsa amount değerinin uygunluğu kontrol ediliyor.
            let current_volumeAmount, current_volumePrice, current_availableAmount, current_availablePrice;

            let value_asking_price = formTab == "limit" ? Number(inputPrice.current.value) : Number(currentPrice);
            let value_amount = Number(inputAmount.current.value);
            
            //volume
            if(formType == "buy"){
                let effective_processing_price = new Decimal(value_asking_price).mul(new Decimal(1).plus(commission_rate));
                current_volumeAmount = value_amount;
                current_volumePrice = new Decimal(value_amount).mul(effective_processing_price);
            }
            else if(formType == "sell"){
                let effective_processing_price = new Decimal(value_asking_price).mul(new Decimal(1).minus(commission_rate));
                current_volumeAmount = value_amount;
                current_volumePrice = new Decimal(value_amount).mul(effective_processing_price);
            }

            //available
            if(formType == "buy"){
                if(walletPrice != null && inputInfo_step != null && inputInfo_min != null && walletPrice != "NaN" && inputInfo_step != "0" && inputInfo_min != "0"){
                    current_availablePrice = walletPrice;
                    current_availableAmount = getAvailableCountBuy(walletPrice, currentPrice, commission_rate, inputInfo_step, inputInfo_min, 100);
                }
            }
            else if(formType == "sell"){
                if(walletAmount != null && inputInfo_step != null && inputInfo_min != null && walletAmount != "NaN" && inputInfo_step != "0" && inputInfo_min != "0"){
                    let effective_processing_price = new Decimal(currentPrice).mul(new Decimal(1).minus(commission_rate));
                    current_availablePrice = (getAvailableCountSell(walletAmount, inputInfo_step, inputInfo_min, 100) * effective_processing_price);
                    current_availableAmount = walletAmount;
                }
            }
            
            if(formType == "buy" && Number(current_volumePrice) > Number(current_availablePrice)){
                formvalid = 0;
                inputPrice.current.classList.add("danger");
                form.current.getElementsByClassName("section-tradebuysell-inner-results-available")[0].classList.add("danger");
                errormessages.push("There are not enough balance for the order!");
            }
            if(formType == "sell" && Number(current_volumeAmount) > Number(current_availableAmount)){
                formvalid = 0;
                inputAmount.current.classList.add("danger");
                form.current.getElementsByClassName("section-tradebuysell-inner-results-available")[0].classList.add("danger");
                errormessages.push("There are not enough balance for the order!");
            }
        }
        else{//input girdileri boş olmama kriterlerini sağlamıyor ise volume ve fee alanları 0 olarak gösteriliyor
            setVolumePrice(0);
            setVolumeAmount(0);
            setFee(0);
        }

        if(formpost && formvalid == 1){//post edilme aşaması
            const data = new FormData(form.current);

            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/spotorder', 
                JSON.stringify(Object.fromEntries(data)),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200" && response.data.message == "Spot order has been added successfully."){
                    clearalldata();
                    toast.success("Spot order has been added successfully!");
                    onOrderDataReceived(response.data.data);
                }
                else if(response.data.message == "Not enough total_count for order"){
                    toast.error("There are not enough balance for the order!");
                }
                else{
                    toast.error(`Something went wrong!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
                setSubmitTradeLoading(false);
                changeDisabledSubmitButtons(false);
            })
            .catch(function (error) {
                if(error.response.data.error == "Not enough total_count for order"){
                    toast.error("There are not enough balance for the order!");
                }
                else{
                    toast.error(`Something went wrong!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                }
                setSubmitTradeLoading(false);
                changeDisabledSubmitButtons(false);
            });
        }
        else if(formpost && formvalid == 0){//hata mesajlarının gösterilme aşaması
            for (let i = 0; i < errormessages.length; i++) {
                toast.error(errormessages[i]);
            }
            setSubmitTradeLoading(false);
            changeDisabledSubmitButtons(false);
        }
        else{
            setSubmitTradeLoading(false);
            changeDisabledSubmitButtons(false);
        }
        
    }

    const directFormControl = (formpost) => {
        let valuePrice = formTab == "limit" ? Number(inputPrice.current.value) : Number(currentPrice);

        if(lastUpdater == "Amount"){
            if(inputAmount.current.value != ""){
                let maxAmount;
                if(formType == "buy"){
                    if(valuePrice && valuePrice !== 0){
                        maxAmount = getAvailableCountBuy(walletPrice, valuePrice, commission_rate, inputInfo_step, inputInfo_min, 100);
                    }
                }
                else if(formType == "sell"){
                    maxAmount = getAvailableCountSell(walletAmount, inputInfo_step, inputInfo_min, 100);
                }
    
                if(maxAmount && maxAmount !== 0){
                    const percentageValue = (Number(inputAmount.current.value) / maxAmount) * 100;
                    inputPercentage.value = percentageValue;
                    setPercentageSliderValue(percentageValue);
                }

                // Total (volume price) hesaplaması:
                let effectiveProcessingPrice;
                if (formType == "buy") {
                    effectiveProcessingPrice = new Decimal(valuePrice).mul(new Decimal(1).plus(commission_rate));
                }
                else if (formType == "sell") {
                    effectiveProcessingPrice = new Decimal(valuePrice).mul(new Decimal(1).minus(commission_rate));
                }
                const totalValue = new Decimal(inputAmount.current.value).mul(effectiveProcessingPrice);
                inputTotal.current.value = totalValue.toString();
            }
            else{
                inputTotal.current.value = "";
                inputPercentage.current = null;
                setPercentageSliderValue(0);
            }
        }
        else if(lastUpdater == "Total"){
            if(inputTotal.current.value != ""){
                let effectiveProcessingPrice;
                if (formType == "buy") {
                    effectiveProcessingPrice = new Decimal(valuePrice).mul(new Decimal(1).plus(commission_rate));
                }
                else if (formType == "sell") {
                    effectiveProcessingPrice = new Decimal(valuePrice).mul(new Decimal(1).minus(commission_rate));
                }

                const amountValue = new Decimal(inputTotal.current.value).div(effectiveProcessingPrice);
                inputAmount.current.value = amountValue.toString();

                // Yüzdeyi hesaplayın
                let maxAmount;
                if (formType == "buy") {
                    maxAmount = getAvailableCountBuy(walletPrice, valuePrice, commission_rate, inputInfo_step, inputInfo_min, 100);
                }
                else if (formType == "sell") {
                    maxAmount = getAvailableCountSell(walletAmount, inputInfo_step, inputInfo_min, 100);
                }
                
                if (maxAmount && maxAmount !== 0) {
                    const percentageValue = (Number(inputAmount.current.value) / maxAmount) * 100;
                    inputPercentage.value = percentageValue;
                    setPercentageSliderValue(percentageValue);
                }
            }
            else{
                inputAmount.current.value = "";
                inputPercentage.current = null;
                setPercentageSliderValue(0);
            }
        }
        else if(lastUpdater == "Percentage"){
            if(Number(inputPercentage.current) != 0){
                let valueCount;
                
                if(formType == "buy"){
                    if(valuePrice != 0 && valuePrice != null){
                        valueCount = getAvailableCountBuy(walletPrice, valuePrice, commission_rate, inputInfo_step, inputInfo_min, Number(inputPercentage.current));
                        inputAmount.current.value = getValueWithShowPlaces(inputInfo_countPlaces, valueCount);
                    }
                }
                else if(formType == "sell"){
                    valueCount = getAvailableCountSell(walletAmount, inputInfo_step, inputInfo_min, Number(inputPercentage.current));
                    inputAmount.current.value = getValueWithShowPlaces(inputInfo_countPlaces, valueCount);
                }

                // Total (volume price) hesaplaması:
                let effectiveProcessingPrice;
                if (formType == "buy") {
                    effectiveProcessingPrice = new Decimal(valuePrice).mul(new Decimal(1).plus(commission_rate));
                }
                else if (formType == "sell") {
                    effectiveProcessingPrice = new Decimal(valuePrice).mul(new Decimal(1).minus(commission_rate));
                }
                const totalValue = inputAmount.current.value ? new Decimal(inputAmount.current.value).mul(effectiveProcessingPrice) : 0;
                inputTotal.current.value = totalValue.toString();
            }
            else{
                inputAmount.current.value = "0";
                inputTotal.current.value = "0";
            }
        }
        formControl(formpost);
        setVolumeAndFee();
    }

    const changeDisabledSubmitButtons = (disabled) => {
        let elements = document.getElementsByClassName("section-tradebuysell-inner-button");

        for (let i = 0; i < elements.length; i++) {
            elements[i].disabled = disabled;
        }
    }

    useEffect(() => {//lastUpdater güncellendiyse. amount, total, percentage değerlerinden biri olabilir.
        if(!firstRunForPageOpening){
            setFirstRunForPageOpening(true);
            return;
        }
        directFormControl(false);
    }, [lastUpdater]);

    useEffect(() => {//limit - ilk current price değerleri verilecek ya da post sonrası güncellenecek
        if(formTab == "limit" && !currentPriceForLimitAskingPrice && currentPrice != "NaN"){
            if(inputPrice.current.value == ""){
                inputPrice.current.value = formatNumberTrimTrailingZeros(currentPrice);
                if(inputAmount.current.value != ""){
                    directFormControl(false);
                }
            }
            setCurrentPriceForLimitAskingPrice(true);
        }
    }, [currentPriceForLimitAskingPrice, currentPrice, formTab]);

    useEffect(() => {//limit - selectedProduct güncellenirse current price değerleri tekrar verilecek
        if(formTab == "limit" && currentPriceForLimitAskingPrice && currentPrice != "NaN"){
            inputPrice.current.value = formatNumberTrimTrailingZeros(currentPrice);
            if(inputAmount.current.value != ""){
                directFormControl(false);
            }
        }
    }, [selectedProduct]);

    useEffect(() => {//limit - volume, fee
        if(formTab == "limit"){
            setVolumeAndFee();
        }
    }, [commission_rate, showPlacesAmount, showPlacesPrice]);

    useEffect(() => {//market - volume, fee
        if(formTab == "market"){
            setVolumeAndFee();
        }
    }, [currentPrice, commission_rate, showPlacesAmount, showPlacesPrice]);

    useEffect(() => {//buy - available
        if(formType == "buy"){
            if(walletPrice != null && inputInfo_step != null && inputInfo_min != null && walletPrice != "NaN" && inputInfo_step != "0" && inputInfo_min != "0"){
                setAvailablePrice(walletPrice);
                setAvailableAmount(getAvailableCountBuy(walletPrice, currentPrice, commission_rate, inputInfo_step, inputInfo_min, 100));
            }
        }
    }, [currentPrice, walletPrice, commission_rate, inputInfo_step, inputInfo_min]);

    useEffect(() => {//sell - available
        if(formType == "sell"){
            if(walletAmount != null && inputInfo_step != null && inputInfo_min != null && walletAmount != "NaN" && inputInfo_step != "0" && inputInfo_min != "0"){
                let effective_processing_price = new Decimal(currentPrice).mul(new Decimal(1).minus(commission_rate));
                setAvailablePrice((getAvailableCountSell(walletAmount, inputInfo_step, inputInfo_min, 100) * effective_processing_price));
                setAvailableAmount(walletAmount);
            }
        }
    }, [currentPrice, walletAmount, commission_rate, inputInfo_step, inputInfo_min]);
    
    useEffect(() => {//selectedProduct güncellenirse input verileri sıfırlanıyor
        clearalldata();
        setTimeout(() => {
            clearalldanger();
        }, 0);
    }, [selectedProduct]);
    
    return(
        <form ref={form} className={`section-tradebuysell-inner section-tradebuysell-${formType}`}>
            <div className="section-tradebuysell-inner-mainsegment">
                <input type="hidden" name="product_id" value={productInfo[2]} />
                <input type="hidden" name="type" value={`${formTab}${formType}`} />
                <div>
                    <div className="section-tradebuysell-inner-input">
                        <label className="section-tradebuysell-inner-input-title" onClick={() => inputPrice.current.focus()}>Price</label>
                        <input type="number" ref={inputPrice} step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} data-name_info="Price" value={formTab == "market" ? formatNumberTrimTrailingZeros(currentPrice) : undefined} name={formTab == "limit" ? "asking_price" : undefined} className={formTab == "market" ? "locked" : undefined} onChange={() => directFormControl(false)} disabled={formTab == "market" ? true : false} />
                        <label className="section-tradebuysell-inner-input-label" onClick={() => inputPrice.current.focus()}>{productInfo[1] ? productInfo[1] : "NaN"}</label>
                    </div>
                    <div className="section-tradebuysell-inner-input">
                        <label className="section-tradebuysell-inner-input-title" onClick={() => inputAmount.current.focus()}>Amount</label>
                        <input type="number" ref={inputAmount} step={getMinAndStepWithShowPlaces(inputInfo_countPlaces)} min={inputInfo_min} data-name_info="Amount" name="count" onChange={() => {if(lastUpdater == "Amount"){directFormControl(false);}else{setLastUpdater("Amount");}}} />
                        <label className="section-tradebuysell-inner-input-label" onClick={() => inputAmount.current.focus()}>{productInfo[0] ? productInfo[0] : "NaN"}</label>
                    </div>
                    <div className="section-tradebuysell-inner-input">
                        <label className="section-tradebuysell-inner-input-title" onClick={() => inputTotal.current.focus()}>Total</label>
                        <input type="number" ref={inputTotal} step={getMinAndStepWithShowPlaces(showPlacesPrice)} min={getMinAndStepWithShowPlaces(showPlacesPrice)} data-name_info="Total" onChange={() => {if(lastUpdater == "Total"){directFormControl(false);}else{setLastUpdater("Total");}}} />
                        <label className="section-tradebuysell-inner-input-label" onClick={() => inputTotal.current.focus()}>{productInfo[1] ? productInfo[1] : "NaN"}</label>
                    </div>
                    <div className="section-tradebuysell-inner-range">
                        <Slider min={0} max={100} step={1} disabled={isAuthenticated ? false : true} value={percentageSliderValue} onChange={(value) => {setPercentageSliderValue(value);inputPercentage.current = value;if(lastUpdater == "Percentage"){directFormControl(false);}else{setLastUpdater("Percentage");}}} marks={{0: "0", 25: "25", 50: "50", 75: "75", 100: "100"}} />
                    </div>
                </div>
                {formType == "buy" ? 
                <div>
                    <div className="section-tradebuysell-inner-input2">
                        <i className="fa-solid fa-plus" onClick={() => inputStopLoss.current.focus()} style={inputStopLossActive ? {display : "none"} : {}}></i>
                        <label className={inputStopLossActive ? "active" : undefined}>Stop Loss</label>
                        <input type="number" ref={inputStopLoss} className={inputStopLossActive ? undefined : "locked"} step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} name={inputStopLossActive ? "stop_loss" : undefined} onChange={() => formControl(false)} onBlur={() => {if(inputStopLoss.current.value == ""){setInputStopLossActive(false);}}} onFocus={() => {setInputStopLossActive(true);}} onClick={() => {setInputStopLossActive(true);}} />
                        <span className="section-tradebuysell-inner-input-label" onClick={() => inputStopLoss.current.focus()}>{productInfo[1] ? productInfo[1] : "NaN"}</span>
                    </div>
                    <div className="section-tradebuysell-inner-input2">
                        <i className="fa-solid fa-plus" onClick={() => inputTakeProfit.current.focus()} style={inputTakeProfitActive ? {display : "none"} : {}}></i>
                        <label className={inputTakeProfitActive ? "active" : undefined}>Take Profit</label>
                        <input type="number" ref={inputTakeProfit} className={inputTakeProfitActive ? undefined : "locked"} step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} name={inputTakeProfitActive ? "take_profit" : undefined} onChange={() => formControl(false)} onBlur={() => {if(inputTakeProfit.current.value == ""){setInputTakeProfitActive(false);}}} onFocus={() => {setInputTakeProfitActive(true);}} onClick={() => {setInputTakeProfitActive(true);}} />
                        <span className="section-tradebuysell-inner-input-label" onClick={() => inputTakeProfit.current.focus()}>{productInfo[1] ? productInfo[1] : "NaN"}</span>
                    </div>
                </div>
                : ""
                }
                <div>
                    <div className="section-tradebuysell-inner-results">
                        <div className="section-tradebuysell-inner-results-available">
                            <span>Available:</span>
                            <span><label>{formType == "buy" ? formatValue3(availablePrice, showPlacesPrice) : formatValue3(availableAmount, showPlacesAmount)}</label> <label>{formType == "buy" ? productInfo[1] : productInfo[0]}</label> ≈ <label>{formType == "buy" ? formatValue3(availableAmount, showPlacesAmount) : formatValue3(availablePrice, showPlacesPrice)}</label> <label>{formType == "buy" ? productInfo[0] : productInfo[1]}</label></span>
                        </div>
                        <div>
                            <span>Volume:</span>
                            <span><label>{formType == "buy" ? formatValue3(volumePrice, showPlacesPrice) : formatValue3(volumeAmount, showPlacesAmount)}</label> <label>{formType == "buy" ? productInfo[1] : productInfo[0]}</label> = <label>{formType == "buy" ? formatValue3(volumeAmount, showPlacesAmount) : formatValue3(volumePrice, showPlacesPrice)}</label> <label>{formType == "buy" ? productInfo[0] : productInfo[1]}</label></span>
                        </div>
                        <div>
                            <span>Fee:</span>
                            <span><label>{fee}</label> <label>{productInfo[1]}</label></span>
                        </div>
                    </div>
                    {!isAuthenticated ?
                    <button type="button" className="section-tradebuysell-inner-button with-link">
                        <Link to="/login">Login</Link>or<Link to="/register">Register</Link>
                    </button>
                    :
                    (userInfo && userInfo.spot_permission && userInfo.spot_permission == "True" ? 
                    <button type="button" className="section-tradebuysell-inner-button" disabled={submitTradeLoading} onClick={() => formControl(true)}>
                        {submitTradeLoading ? <Loading /> : (formType == "buy" ? "BUY" : "SELL")}
                    </button>
                    : 
                    <button type="button" className="section-tradebuysell-inner-button with-link">
                        <Link to="/verification">You must be a level 2 approved account</Link>
                    </button>
                    )}
                </div>
            </div>
        </form>
    );
}

const SectionTradeBuySell = ({ onOrderDataReceived }) => {

    const { isAuthenticated } = useContext(AuthContext);

    const selectedProduct = useSelector((state) => state.kprice.selectedProduct);

    const [tabValue, setTabValue] = useState("Market");
    const [buySellValue, setBuySellValue] = useState("Buy");

    const productsData = useSelector((state) => state.kprice.products.data);
    const productsFastData = useSelector((state) => state.kprice.productsFast.data);
    const walletData = useSelector((state) => state.kprice.wallet.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);

    const [walletPrice, setWalletPrice] = useState("NaN");
    const [walletAmount, setWalletAmount] = useState("NaN");

    useEffect(() => {//hesaplamalarda kullanılacak wallet price ve wallet amount değerleri
        if(walletData){
            let price = selectedProduct && walletData[selectedProduct.split("-")[1]] && walletData[selectedProduct.split("-")[1]].count ? walletData[selectedProduct.split("-")[1]].count : 0;
            let amount = selectedProduct && walletData[selectedProduct.split("-")[0]] && walletData[selectedProduct.split("-")[0]].count ? walletData[selectedProduct.split("-")[0]].count : 0;
            
            setWalletPrice(price);
            setWalletAmount(amount);
        }
    }, [walletData]);
    
    return(
        <div className="allsections allsections-border section-tradebuysell">
            <div className="section-tradebuysell-options">
                <span className={tabValue == "Market" ? "active" : undefined} onClick={() => setTabValue("Market")}>Market</span>
                <span className={tabValue == "Limit" ? "active" : undefined} onClick={() => setTabValue("Limit")}>Limit</span>
            </div>
            {tabValue == "Market" && <div className="section-tradebuysell-tab-area">
                <div className="buy-sell-tab-main-area">
                    <div className="buy-sell-tab-area">
                        <div className={buySellValue == "Buy" ? "buy active" : "buy"} onClick={() => setBuySellValue('Buy')}>Buy</div>
                        <div className={buySellValue == "Sell" ? "sell active" : "sell"} onClick={() => setBuySellValue('Sell')}>Sell</div>
                    </div>
                </div>
                {buySellValue == "Buy" && <CreateForm onOrderDataReceived={onOrderDataReceived} selectedProduct={selectedProduct} productsData={productsData} productsFastData={productsFastData} currenciesData={currenciesData} walletPrice={isAuthenticated ? walletPrice : 0} walletAmount={isAuthenticated ? walletAmount : 0} isAuthenticated={isAuthenticated} formTab="market" formType="buy" />}
                {buySellValue == "Sell" && <CreateForm onOrderDataReceived={onOrderDataReceived} selectedProduct={selectedProduct} productsData={productsData} productsFastData={productsFastData} currenciesData={currenciesData} walletPrice={isAuthenticated ? walletPrice : 0} walletAmount={isAuthenticated ? walletAmount : 0} isAuthenticated={isAuthenticated} formTab="market" formType="sell" />}
            </div>}
            {tabValue == "Limit" && <div className="section-tradebuysell-tab-area">
                <div className="buy-sell-tab-main-area">
                    <div className="buy-sell-tab-area">
                        <div className={buySellValue == "Buy" ? "buy active" : "buy"} onClick={() => setBuySellValue('Buy')}>Buy</div>
                        <div className={buySellValue == "Sell" ? "sell active" : "sell"} onClick={() => setBuySellValue('Sell')}>Sell</div>
                    </div>
                </div>
                {buySellValue == "Buy" && <CreateForm onOrderDataReceived={onOrderDataReceived} selectedProduct={selectedProduct} productsData={productsData} productsFastData={productsFastData} currenciesData={currenciesData} walletPrice={isAuthenticated ? walletPrice : 0} walletAmount={isAuthenticated ? walletAmount : 0} isAuthenticated={isAuthenticated} formTab="limit" formType="buy" />}
                {buySellValue == "Sell" && <CreateForm onOrderDataReceived={onOrderDataReceived} selectedProduct={selectedProduct} productsData={productsData} productsFastData={productsFastData} currenciesData={currenciesData} walletPrice={isAuthenticated ? walletPrice : 0} walletAmount={isAuthenticated ? walletAmount : 0} isAuthenticated={isAuthenticated} formTab="limit" formType="sell" />}
            </div>}
        </div>
    )
    
}

export default SectionTradeBuySell;