import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { formatValuePriceControl, formatValue3, formatValue4 } from '../Lib/calculationFunctions';
import Loading from "./Global/Loading";
import { MaterialReactTable } from 'material-react-table';
import "../Pages/page_dashboard.css";

const SearchHistory = ({ pageLink, close }) => {

    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const productsData = useSelector((state) => state.kprice.products.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const [searchHistory, setSearchHistory] = useState(["", "", ""]);
    const [coinListSearch, setCoinListSearch] = useState("");
    const [searchHistoryIsEmpty, setSearchHistoryIsEmpty] = useState(true);

    useEffect(() => {
        const storedHistory = localStorage.getItem("searchHistory");
        if (storedHistory) {
            setSearchHistory(JSON.parse(storedHistory));
        }
    }, []);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "searchHistory") {
                const updatedHistory = JSON.parse(event.newValue);
                setSearchHistory(updatedHistory);
            }
        };
    
        window.addEventListener("storage", handleStorageChange);
    
        return() => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const updateSearchHistory = (newSearch) => {
        let newHistory = [...searchHistory];

        if(newSearch == "clear"){
            if(searchHistory[0] != "" || searchHistory[1] != "" || searchHistory[2] != ""){
                newHistory = ["", "", ""];
                setSearchHistory(newHistory);
                localStorage.setItem("searchHistory", JSON.stringify(newHistory));
            }
        }
        else if(newSearch){
            const index = newHistory.indexOf(newSearch);

            if(index > -1){
                newHistory.splice(index, 1);
            }
            else{
                newHistory.pop();
            }

            newHistory.unshift(newSearch);
            setSearchHistory(newHistory);
            localStorage.setItem("searchHistory", JSON.stringify(newHistory));
        }
    }

    const handleChangeText = (text) => {
        const filteredText = text.toUpperCase().replace(/[^A-Z0-9/]/g, '');
        setCoinListSearch(filteredText);
    };

    const [tableProducts, setTableProducts] = useState(null);//tablo verileri

    const columnsProducts = useMemo(() => [//tablo bilgileri
        { accessorKey: 'searchName1', header: 'Name', size: 80, Cell: ({ row }) => row.original.nameForTable },//row.original.nameForTable geçen yer newData içindeki changeForSorting değeri
        { accessorKey: 'price', header: 'Price', size: 40, Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'change', header: 'Change', size: 40, Cell: ({ row }) => row.original.changeForTable },//row.original.changeForTable geçen yer newData içindeki changeForSorting değeri
        { accessorKey: 'quantity', header: 'Quantity', size: 0, show: false },
    ], []);

    const calcPrice = (price, show_places) => {
        const response = formatValuePriceControl(price, show_places);
        if(response){
            return formatValue3(price, show_places);
        }
        else{
            return formatValue4(price);
        }
    }

    const tableProducts_name = (key) => {
        let currenciesName = key.split("-")[0];
        return <div className="section-currencystatus-element-link chart" key={key}>
            <div className="section-currencystatus-element-name">
                <Link to={`/${pageLink}?market=${key}`} className="innerlink" onClick={() => {updateSearchHistory(key);close();}}>
                    <img src={currenciesData && currenciesData[currenciesName] ? currenciesData[currenciesName].picture_url : undefined} />
                </Link>
                <Link to={`/${pageLink}?market=${key}`} className="innerlink" onClick={() => {updateSearchHistory(key);close();}}>
                    <div className="nametext">
                        <label>{currenciesData && currenciesData[currenciesName] ? currenciesData[currenciesName].currency_name : ""}</label>
                        <span><p>{key.split("-")[0]}</p>/{key.split("-")[1]}</span>
                    </div>
                </Link>
            </div>
        </div>;
    }

    const tableProducts_price = (key) => {
        let show_places = productsData && productsData[key] && productsData[key].show_places ? productsData[key].show_places : "NaN";
        let currentPrice = productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].currentPrice ? productsMiniTickerData[key].currentPrice : null;
        let previousCurrentPrice = productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].previousCurrentPrice ? productsMiniTickerData[key].previousCurrentPrice : null;
        
        if(currentPrice && previousCurrentPrice && document.getElementById(`status-element-${key}`)){
            let currentCP = document.getElementById(`status-element-${key}`).dataset.current_price;
            let currentPCP = document.getElementById(`status-element-${key}`).dataset.previous_current_price;
            if(currentCP != currentPrice || currentPCP != previousCurrentPrice){
                if(currentPrice > previousCurrentPrice){
                    document.getElementById(`status-element-${key}`).classList.remove("success", "danger");
                    setTimeout(() => {
                        if(document.getElementById(`status-element-${key}`)){
                            document.getElementById(`status-element-${key}`).classList.add("success");
                        }
                    }, 10);
                }
                else if(currentPrice < previousCurrentPrice){
                    document.getElementById(`status-element-${key}`).classList.remove("success", "danger");
                    setTimeout(() => {
                        if(document.getElementById(`status-element-${key}`)){
                            document.getElementById(`status-element-${key}`).classList.add("danger");
                        }
                    }, 10);
                }
                document.getElementById(`status-element-${key}`).dataset.current_price = currentPrice;
                document.getElementById(`status-element-${key}`).dataset.previous_current_price = previousCurrentPrice;
            }
        }
        return <Link to={`/${pageLink}?market=${key}`} key={key} className="section-currencystatus-element-link" onClick={() => {updateSearchHistory(key);close();}}>
            <div id={`status-element-${key}`} className="section-currencystatus-element-price riseAndFallValueAnimation" data-current_price="" data-previous_current_price="">
                {currentPrice ? calcPrice(currentPrice, show_places) : "NaN"}
            </div>
        </Link>;
    }

    const tableProducts_change = (key) => {
        return <Link to={`/${pageLink}?market=${key}`} key={key} className="section-currencystatus-element-link" onClick={() => {updateSearchHistory(key);close();}}>
            <div className="section-currencystatus-element-change">
                <div className={productsMiniTickerData && productsMiniTickerData[key] ? (productsMiniTickerData[key].percentage_difference_24h != "" && productsMiniTickerData[key].percentage_difference_24h != null && productsMiniTickerData[key].percentage_difference_24h != undefined ? (productsMiniTickerData[key].percentage_difference_24h >= 0 ? "success" : "danger") : "danger") : "danger"}>{productsMiniTickerData && productsMiniTickerData[key] ? (productsMiniTickerData[key].percentage_difference_24h != "" && productsMiniTickerData[key].percentage_difference_24h != null && productsMiniTickerData[key].percentage_difference_24h != undefined ? formatValue3(productsMiniTickerData[key].percentage_difference_24h, 2) : "NaN") : "NaN"}%</div>
            </div>
        </Link>;
    }

    useEffect(() => {
        const productKeys = productsMiniTickerData ? Object.keys(productsMiniTickerData) : [];

        const processData = () => {
            const newData = [];
            
            productKeys.forEach((key) => {
                let productStatusSpotActive = productsData && productsData[key] && productsData[key].status_spot && productsData[key].status_spot == "active" ? true : false;
                let productStatusFutureActive = productsData && productsData[key] && productsData[key].status_future && productsData[key].status_future == "active" ? true : false;
                if((pageLink == "trade" && productStatusSpotActive) || (pageLink == "future" && productStatusFutureActive)){
                    newData.push({
                        key: key,
                        name: currenciesData && currenciesData[key.split("-")[0]] ? currenciesData[key.split("-")[0]].currency_name : "",
                        nameForTable: tableProducts_name(key),
                        price: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].currentPrice ? productsMiniTickerData[key].currentPrice : 0,
                        priceForTable: tableProducts_price(key),
                        change: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].percentage_difference_24h != null ? productsMiniTickerData[key].percentage_difference_24h : 0,
                        changeForTable: tableProducts_change(key),
                        searchName1: `${key.split("-")[0]}/${key.split("-")[1]}`,
                        searchName2: `${key.split("-")[0]}${key.split("-")[1]}`,
                        quantity: productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].q_24h != null ? productsMiniTickerData[key].q_24h : 0,
                    });
                }
            });

            let sortedData = newData;

            if(coinListSearch != ""){
                const filtered = sortedData.filter(item => 
                    item.searchName1.toLowerCase().includes(coinListSearch.toLowerCase()) ||
                    item.searchName2.toLowerCase().includes(coinListSearch.toLowerCase())
                );

                sortedData = [...filtered].sort((a, b) => {
                    if (a.searchName1 < b.searchName1) return -1;
                    if (a.searchName1 > b.searchName1) return 1;
                    return 0;
                });
            }
            else{
                sortedData = [...sortedData].sort((a, b) => {
                    if (a.quantity < b.quantity) {
                        return 1;
                    }
                    if (a.quantity > b.quantity) {
                        return -1;
                    }
                    return 0;
                });
            }
            
            return sortedData;
        }

        setTableProducts(processData());
    }, [productsMiniTickerData, currenciesData, coinListSearch]);

    useEffect(() => {
        if(searchHistory[0] != "" && ((pageLink == "trade" && productsData[searchHistory[0]].status_spot && productsData[searchHistory[0]].status_spot == "active") || (pageLink == "future" && productsData[searchHistory[0]].status_future && productsData[searchHistory[0]].status_future == "active"))){
            setSearchHistoryIsEmpty(false);
        }
        else if(searchHistory[1] != "" && ((pageLink == "trade" && productsData[searchHistory[1]].status_spot && productsData[searchHistory[1]].status_spot == "active") || (pageLink == "future" && productsData[searchHistory[1]].status_future && productsData[searchHistory[1]].status_future == "active"))){
            setSearchHistoryIsEmpty(false);
        }
        else if(searchHistory[2] != "" && ((pageLink == "trade" && productsData[searchHistory[2]].status_spot && productsData[searchHistory[2]].status_spot == "active") || (pageLink == "future" && productsData[searchHistory[2]].status_future && productsData[searchHistory[2]].status_future == "active"))){
            setSearchHistoryIsEmpty(false);
        }
        else{
            setSearchHistoryIsEmpty(true);
        }
    }, [searchHistory, productsData]);

    return(
        <div className="section-walletlist-dialog-transfer">
            <div id="section-currencystatus" className="section-currencystatus search-history">
                {tableProducts == null ? (
                    <div className="currencyStatusLoading"><Loading /></div>
                ) : (
                    <div className={!(coinListSearch == "" && !searchHistoryIsEmpty) ? "full-list" : ""}>
                        <i className="fa-solid fa-magnifying-glass search-icon"></i>
                        <input type="text" placeholder="Search" className="search" onChange={(e) => handleChangeText(e.target.value)} />
                        {coinListSearch == "" && !searchHistoryIsEmpty && 
                            <div className="no-search">
                                <div className="title">
                                    <span>Search History</span>
                                    <i className="fa-solid fa-trash" onClick={() => updateSearchHistory("clear")}></i>
                                </div>
                                <div className="history">
                                    {searchHistory.map((item, index) => (
                                        (item != "" && ((pageLink == "trade" && productsData && productsData[item] && productsData[item].status_spot && productsData[item].status_spot == "active" ? true : false) || (pageLink == "future" && productsData && productsData[item] && productsData[item].status_future && productsData[item].status_future == "active" ? true : false)) && 
                                            <Link to={`/${pageLink}?market=${item}`} key={index} className="innerlink" onClick={() => {updateSearchHistory(item);close();}}>
                                                {`${item.split("-")[0]} / ${item.split("-")[1]}`}
                                            </Link>
                                        )
                                    ))}
                                </div>
                            </div>
                        }
                        {tableProducts.length > 0 ? 
                            <MaterialReactTable 
                                columns={columnsProducts} 
                                data={tableProducts} 
                                enablePagination={false}
                                enableRowVirtualization={true}
                                enableColumnActions={false}
                                initialState={{
                                    columnVisibility: { quantity: false },
                                }} 
                                className="section-currencystatus-table" 
                            />
                            : 
                            <div className="no-search no-result">
                                <div className="history">
                                    <div className="no-history">
                                        <i className="fa-regular fa-circle-xmark"></i>
                                        <span>No results</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )}
            </div>
        </div>
    )
    
}

export default SearchHistory;