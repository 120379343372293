import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { formatValuePriceControl, formatValue3, formatValue4 } from '../../Lib/calculationFunctions';
import { onCurrencyStatusOpen, onUpdateFavoritesByApi } from "../../Reducer/kpriceReducer";
import { toast } from 'react-toastify';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { AuthContext } from '../../components/AuthContext/AuthContext';
import { Dialog } from 'primereact/dialog';
import SearchHistory from '../../components/SearchHistory';

const SectionTradeMainChartTopTitle = ({ pageLink }) => {

    const { isAuthenticated, user, signOut } = useContext(AuthContext);

    const dispatch = useDispatch();

    const favoritesData = useSelector((state) => state.kprice.favorites.data);
    const [displayFavoriteDialog, setDisplayFavoriteDialog] = useState(false);//favorite popup görünürlüğünü tutuyor
    const [displaySearchDialog, setDisplaySearchDialog] = useState(false);
    const selectedProduct = useSelector((state) => state.kprice.selectedProduct);
    const selectedProductName = selectedProduct ? selectedProduct.replace("-", "/") : "NaN";

    const [currentPrice, setCurrentPrice] = useState("NaN");
    const [high24h, setHigh24h] = useState("NaN");
    const [low24h, setLow24h] = useState("NaN");

    const productsData = useSelector((state) => state.kprice.products.data);
    const productsFastData = useSelector((state) => state.kprice.productsFast.data);
    const show_places = productsData && productsData[selectedProduct] && productsData[selectedProduct].show_places ? productsData[selectedProduct].show_places : "NaN";
    const q_24h = productsFastData && productsFastData[selectedProduct] && productsFastData[selectedProduct].q_24h != "" && productsFastData[selectedProduct].q_24h != null && productsFastData[selectedProduct].q_24h != undefined ? formatValue3(productsFastData[selectedProduct].q_24h, 2) : "NaN";
    const v_24h = productsFastData && productsFastData[selectedProduct] && productsFastData[selectedProduct].v_24h != "" && productsFastData[selectedProduct].v_24h != null && productsFastData[selectedProduct].v_24h != undefined ? formatValue3(productsFastData[selectedProduct].v_24h, 2) : "NaN";
    const flags = productsFastData && productsFastData[selectedProduct] && productsFastData[selectedProduct].flags != null ? productsFastData[selectedProduct].flags : "NaN";
    const price_difference_24h = productsFastData && productsFastData[selectedProduct] && productsFastData[selectedProduct].price_difference_24h != "" && productsFastData[selectedProduct].price_difference_24h != null && productsFastData[selectedProduct].price_difference_24h != undefined ? productsFastData[selectedProduct].price_difference_24h : "NaN";
    const percentage_difference_24h = productsFastData && productsFastData[selectedProduct] && productsFastData[selectedProduct].percentage_difference_24h != "" && productsFastData[selectedProduct].percentage_difference_24h != null && productsFastData[selectedProduct].percentage_difference_24h != undefined ? productsFastData[selectedProduct].percentage_difference_24h : "NaN";
    
    const [flagsValue, setFlagsValue] = useState(null);
    const [viewType, setViewType] = useState("pc");

    function adjustFavoritesData(data) {
        let adjustedData = { ...data };
    
        if (adjustedData && adjustedData.item) {
            const item = adjustedData.item;
            
            if (item.timestamp) {
                item.timestamp = Number(item.timestamp);
            }
        }
    
        return adjustedData;
    }
    
    const currencyStatusClickStar = async (event, key) => {
        if(isAuthenticated){
            let favoriteType = event.currentTarget.classList.contains('active') ? "remove" : "add";

            if(favoriteType == "add"){
                event.currentTarget.classList.add("active");
            }
            else if(favoriteType == "remove"){
                event.currentTarget.classList.remove("active");
            }

            if(key != null && favoriteType != null){

                let data = {
                    "product_id": key,
                    "type": favoriteType
                }

                const session = await Auth.currentSession();
                const jwtToken = session.getIdToken().getJwtToken();

                axios.post('https://api.kpricemarket.com/favorites', 
                    JSON.stringify(data),
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                )
                .then(function (response) {

                    const newFavorite = adjustFavoritesData(response.data);

                    if(response.status == "200"){
                        dispatch(onUpdateFavoritesByApi({ newFavorite }));
                    }
                    else{
                        if(favoriteType == "add"){//işareti kaldırıyoruz
                            event.currentTarget.classList.remove("active");
                        }
                        else if(favoriteType == "remove"){//tekrar işaretliyoruz
                            event.currentTarget.classList.add("active");
                        }
                    }
                })
                .catch(function (error) {
                    toast.error(error.response.data.error);
                    //hatalı durum için
                    if(favoriteType == "add"){//işareti kaldırıyoruz
                        event.currentTarget.classList.remove("active");
                    }
                    else if(favoriteType == "remove"){//tekrar işaretliyoruz
                        event.currentTarget.classList.add("active");
                    }
                });

            }
        }
        else{
            setDisplayFavoriteDialog(true);
        }
    }

    useEffect(() => {
        if(flags == "1" || flags == "2"){
            setFlagsValue(flags);
        }
    }, [flags]);

    useEffect(() => {
        const checkScreenWidth = () => {
            if(window.innerWidth <= 650){
                setViewType("mobile");
            }
            else{
                setViewType("pc");
            }
        }

        checkScreenWidth();
        
        window.addEventListener('resize', checkScreenWidth);
        
        // Component temizlenirken ekran genişliğini dinlemeyi temizleme
        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        }
    }, []);

    useEffect(() => {//favorites verileri
        if(isAuthenticated){
            dispatch(onCurrencyStatusOpen());
        }
    }, [isAuthenticated]);

    useEffect(() => {
        let currentPrice = productsFastData && productsFastData[selectedProduct] && productsFastData[selectedProduct].currentPrice ? productsFastData[selectedProduct].currentPrice : "NaN";
        let high24h = productsFastData && productsFastData[selectedProduct] && productsFastData[selectedProduct].h_24h ? productsFastData[selectedProduct].h_24h : "NaN";
        let low24h = productsFastData && productsFastData[selectedProduct] && productsFastData[selectedProduct].l_24h ? productsFastData[selectedProduct].l_24h : "NaN";

        const response_currentPrice = formatValuePriceControl(currentPrice, show_places);
        if(response_currentPrice){
            setCurrentPrice(formatValue3(currentPrice, show_places));
        }
        else{
            setCurrentPrice(formatValue4(currentPrice));
        }

        const response_high24h = formatValuePriceControl(high24h, show_places);
        if(response_high24h){
            setHigh24h(formatValue3(high24h, show_places));
        }
        else{
            setHigh24h(formatValue4(high24h));
        }

        const response_low24h = formatValuePriceControl(low24h, show_places);
        if(response_low24h){
            setLow24h(formatValue3(low24h, show_places));
        }
        else{
            setLow24h(formatValue4(low24h));
        }
    }, [productsData, productsFastData, selectedProduct]);

    return(
        <div className="section-maincharttop-top">
            <Dialog header="Favorite" visible={displayFavoriteDialog} draggable={false} resizable={false} className="section-tradecryptocurrencylist-dialog" onHide={() => setDisplayFavoriteDialog(false)}>
                <label>You must login or register to add to favorites.</label>
                <div className="buttons">
                    <Link to="/login">Login</Link>
                    <Link to="/register">Register</Link>
                </div>
            </Dialog>
            <Dialog header={pageLink == "future" ? "Future Market" : "Spot Market"} visible={displaySearchDialog} draggable={false} resizable={false} className="section-walletlist-dialog transfer" onHide={() => setDisplaySearchDialog(false)}>
                <SearchHistory pageLink={pageLink} close={() => setDisplaySearchDialog(false)} />
            </Dialog>
            <div className="section-maincharttop-top-title">
                <span onClick={() => {setDisplaySearchDialog(true)}}>{selectedProductName}</span>
                {viewType == "mobile" && 
                <div>
                    <i onClick={(event) => currencyStatusClickStar(event, selectedProduct)} className={selectedProduct && favoritesData && favoritesData[selectedProduct] ? "fa-solid fa-star star-icon active" : "fa-solid fa-star star-icon"}></i>
                </div>}
            </div>
            <div className="section-maincharttop-top-data">
                {viewType == "mobile" ? 
                <>
                    <div className="price">
                        <label className={flagsValue == "1" ? "success" : (flagsValue == "2" ? "danger" : undefined)}>{currentPrice}</label>
                        <div>
                            <span><p className={price_difference_24h >= 0 ? "success" : (price_difference_24h < 0 ? "danger" : undefined)}>{formatValue3(price_difference_24h, show_places)}</p><p className={percentage_difference_24h >= 0 ? "success" : (percentage_difference_24h < 0 ? "danger" : undefined)}>{formatValue3(percentage_difference_24h, 2)}%</p></span>
                        </div>
                    </div>
                    <div className="info">
                        <div>
                            <span>24h High</span>
                            <span>{high24h}</span>
                        </div>
                        <div>
                            <span>24h Low</span>
                            <span>{low24h}</span>
                        </div>
                        <div>
                            <span>24h Volume ({selectedProduct ? selectedProduct.split("-")[0] : "NaN"})</span>
                            <span>{v_24h}</span>
                        </div>
                        <div>
                            <span>24h Volume ({selectedProduct ? selectedProduct.split("-")[1] : "NaN"})</span>
                            <span>{q_24h}</span>
                        </div>
                    </div>
                </>
                : 
                <>
                    <label className={flagsValue == "1" ? "success" : (flagsValue == "2" ? "danger" : undefined)}>{currentPrice}</label>
                    <div>
                        <span>24h Change</span>
                        <span><p className={price_difference_24h >= 0 ? "success" : (price_difference_24h < 0 ? "danger" : undefined)}>{formatValue3(price_difference_24h, show_places)}</p><p className={percentage_difference_24h >= 0 ? "success" : (percentage_difference_24h < 0 ? "danger" : undefined)}>{formatValue3(percentage_difference_24h, 2)}%</p></span>
                    </div>
                    <div>
                        <span>24h High</span>
                        <span>{high24h}</span>
                    </div>
                    <div>
                        <span>24h Low</span>
                        <span>{low24h}</span>
                    </div>
                    <div>
                        <span>24h Volume ({selectedProduct ? selectedProduct.split("-")[0] : "NaN"})</span>
                        <span>{v_24h}</span>
                    </div>
                    <div>
                        <span>24h Volume ({selectedProduct ? selectedProduct.split("-")[1] : "NaN"})</span>
                        <span>{q_24h}</span>
                    </div>
                </>}
                
                
            </div>
        </div>
    )

}

export default SectionTradeMainChartTopTitle;