import React, { useEffect, useState, useMemo, useRef } from "react";
import { MaterialReactTable } from 'material-react-table';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { onOrderHistoryChangeTimeInterval } from "../../Reducer/kpriceReducer";
import { useDispatch } from "react-redux";
import axios from 'axios';
import { Auth } from 'aws-amplify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import withLoadingData from "../../Container/withLoadingData";
import Loading from "../../components/Global/Loading";
import isEqual from "react-fast-compare";
import moment from "moment-timezone";
import { formatValuePriceControl, formatValue3, formatValue4, getValueWithShowPlaces, setInputValueByShowPlacesFunction, getMinAndStepWithShowPlaces, valueControlWithDigitLimit } from '../../Lib/calculationFunctions';

const SectionTradeOrderHistory = ({isOrderHistoryLoading, newOrderFromBuySell}) => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const dispatch = useDispatch();
    const [viewType, setViewType] = useState("pc");
    const [activeOption, setActiveOption] = useState("Open Orders");//order history açık sekme değeri
    const closedOrdersSelectedTime = useSelector((state) => state.kprice.selectedOrderHistoryTimeInterval);
    const [closedOrdersTime, setClosedOrdersTime] = useState(closedOrdersSelectedTime ? closedOrdersSelectedTime : "last1w");//closed orders zaman değeri
    const [closedOrdersTimeSelectDisplay, setClosedOrdersTimeSelectDisplay] = useState(false);//closed orders zaman select menüsünün görünürlüğü
    const closedOrdersTimeSelect = useRef(null);//closed orders zaman select'i
    const closedOrdersTimeSelectMenu = useRef(null);//closed orders zaman select menüsü
    const orderHistoryArea = useRef(null);//order history alanı
    const isFirstRenderForTime = useRef(true);

    const [displayEditDialog, setDisplayEditDialog] = useState(false);//order düzenleme popup görünürlüğünü tutuyor
    const [displayConfirm, setDisplayConfirm] = useState(false);//order iptal etme işlemi öncesinde çıkan popup görünürlüğünü tutuyor
    const [currentEditOrder, setCurrentEditOrder] = useState(null);//order iptal etme aşamasında iptal edilecek orderid bilgisini tutuyor
    const [currentDeleteOrderId, setCurrentDeleteOrderId] = useState("");//order iptal etme aşamasında iptal edilecek orderid bilgisini tutuyor

    const [submitOrderUpdateLoading, setSubmitOrderUpdateLoading] = useState(false);

    useEffect(() => {//closed orders süresi güncellenince çalışacak
        if (isFirstRenderForTime.current) {
            isFirstRenderForTime.current = false;
            return;
        }
        const newTimeInterval = closedOrdersTime;
        dispatch(onOrderHistoryChangeTimeInterval({ newTimeInterval }));
    }, [closedOrdersTime]);

    useEffect(() => {//animasyon için - order history sekmesi ve closed orders süresi güncellenince çalışacak
        orderHistoryArea.current.style.animation = "none";
        let tempForAnimation = orderHistoryArea.current.offsetHeight;
        orderHistoryArea.current.style.animation = "open-animation 1s";
    }, [activeOption, closedOrdersTime]);

    const columnsOpen = useMemo(() => [//open orders tablo bilgileri
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable }, //row.original.typeForTable geçen yer newData içindeki typeForTable değeri
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count' },
        { accessorKey: 'stop_loss', header: 'Stop Loss', Cell: ({ row }) => row.original.stop_lossForTable },
        { accessorKey: 'take_profit', header: 'Take Profit', Cell: ({ row }) => row.original.take_profitForTable },
        { accessorKey: 'state', header: 'State' },
    ], []);

    const columnsClosed = useMemo(() => [//closed orders tablo bilgileri
        { accessorKey: 'timestamp', header: 'Date', Cell: ({ row }) => row.original.date },
        { accessorKey: 'market', header: 'Market', Cell: ({ row }) => row.original.marketForTable },
        { accessorKey: 'type', header: 'Type', Cell: ({ row }) => row.original.typeForTable }, //row.original.typeForTable geçen yer newData içindeki typeForTable değeri
        { accessorKey: 'price', header: 'Price', Cell: ({ row }) => row.original.priceForTable },
        { accessorKey: 'count', header: 'Count' },
        { accessorKey: 'total', header: 'Total', Cell: ({ row }) => row.original.totalForTable },
        { accessorKey: 'state', header: 'State' },
    ], []);

    const columnsOpenMobile = useMemo(() => [//open orders mobil tablo bilgileri
        { accessorKey: 'timestamp', header: 'Timestamp' },
        { accessorKey: 'market', header: 'Market' },
        { accessorKey: 'price', header: 'Price' },
        { accessorKey: 'state', header: 'State' },
    ], []);

    const columnsClosedMobile = useMemo(() => [//closed orders mobil tablo bilgileri
        { accessorKey: 'timestamp', header: 'Timestamp' },
        { accessorKey: 'market', header: 'Market' },
        { accessorKey: 'price', header: 'Price' },
        { accessorKey: 'state', header: 'State' },
    ], []);

    //sütuna özel sıralama için gerekli fonksiyonlar - başlangıç
    const fuzzy = (rowA, rowB, columnId) => {
        let dir = 0;
        if (rowA.columnFiltersMeta[columnId]) {
          dir = compareItems(
            rowA.columnFiltersMeta[columnId],
            rowB.columnFiltersMeta[columnId]
          );
        }
        
        return dir === 0
          ? alphanumComparator(rowA, rowB)
          : dir;
    };

    function alphanumComparator(a, b) {
        const regex = /\d+|\D+/g;
      
        const aParts = String(a).match(regex);
        const bParts = String(b).match(regex);
      
        for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
          if (aParts[i] !== bParts[i]) {
            const isNumA = !isNaN(aParts[i]);
            const isNumB = !isNaN(bParts[i]);
      
            if (isNumA && isNumB) {
              return parseInt(aParts[i], 10) - parseInt(bParts[i], 10);
            } else if (isNumA) {
              return -1; // Sayılar metinlerden önce gelsin
            } else if (isNumB) {
              return 1;
            } else {
              return aParts[i].localeCompare(bParts[i]);
            }
          }
        }
        return aParts.length - bParts.length;
    }

    function compareItems(itemA, itemB) {
        if (itemA > itemB) return 1;
        if (itemA < itemB) return -1;
        return 0;
    }
    //sütuna özel sıralama için gerekli fonksiyonlar - son

    const productData = useSelector((state) => state.kprice.products.data);
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const orderHistory = useSelector((state) => state.kprice.orderHistory.data);
    const orderHistoryNewData = orderHistory.updateData;
    const isFirstRenderForNewData = useRef(true);
    const [openOrders, setOpenOrders] = useState([]);
    const [closedOrders, setClosedOrders] = useState([]);

    const [inputNumberMinValue, setInputNumberMinValue] = useState(0);
    const [inputInfo_askingPricePlaces, setInputInfo_askingPricePlaces] = useState(0);
    const [inputInfo_countPlaces, setInputInfo_countPlaces] = useState(0);
    const [inputInfo_maxBeforePlaces, setInputInfo_maxBeforePlaces] = useState(0);
    
    useEffect(() => {//eğer tablo boş dönerse boş olarak güncellenebilsin diye
        setOpenOrders(orderHistory.openOrders ? orderHistory.openOrders : []);
        setClosedOrders(orderHistory.closedOrders ? orderHistory.closedOrders : []);
    }, [orderHistory]);

    const EditIconForm = ({ order }) => {//her waiting satırında gözüken order iptal etme butonu
        return (
            <Tooltip title="Edit" className="editicon" onClick={() => {setCurrentEditOrder(order);setDisplayEditDialog(true);}}>
                <IconButton>
                    <EditIcon />
                </IconButton>
            </Tooltip>
        );
    }

    const CancelIconForm = ({ order, index }) => {//her waiting satırında gözüken order iptal etme butonu
        return (
            <form id={`form-cancel${index}`} className="canceliconform">
                <Tooltip title="Cancel" className="cancelicon" onClick={() => {setCurrentDeleteOrderId(`form-cancel${index}`);setDisplayConfirm(true);}}>
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <input type="hidden" value="remove" name="update_type"/>
                <input type="hidden" value={order.order_id} name="order_id"/>
            </form>
        );
    }

    const cancelIconClick = async (formid) => {//iptal butonuna tıklandığında
        setDisplayConfirm(false);
        let formforcancel = document.getElementById(formid);
        
        const data = new FormData(formforcancel);
        const updateData = Object.fromEntries(data);

        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();
        
        axios.post('https://api.kpricemarket.com/spotorder/update', 
            JSON.stringify(updateData),
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
        )
        .then(function (response) {
            if(response.status == "200" && response.data.message == "Data updated successfully"){
                toast.success("Order deleted successfully!");
                orderHistoryTableUpdate(response.data.data);
            }
            else{
                toast.error(`There was a problem deleting the order!${response.data.message ? ` (${response.data.message})` : ""}`);
            }
        })
        .catch(function (error) {
            toast.error(`There was a problem deleting the order!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
        });
    }

    const editDialogUpdate = async (formpost, event) => {//update input içinde bilgi değiştiğinde ve post edileceğinde
        setSubmitOrderUpdateLoading(true);
        let formvalid = true;
        const form = document.getElementById("edit-order-form");
        const eventValue = event ? event.value : null;

        let dangerElements = form.getElementsByTagName("input");
        for (let i = 0; i < dangerElements.length; i++) {
            dangerElements[i].classList.remove("danger");
        }

        let inputElements = form.getElementsByTagName("input");
        
        if(eventValue){
            if(eventValue.includes('-') || eventValue.includes('e')){
                event.value = "";
            }
            if(eventValue == "00"){
                event.value = "0";
            }
            if(eventValue[0] && eventValue[1]){
                if(eventValue[0] == "0" && eventValue[1] != "." && eventValue[1] != ","){
                    event.value = eventValue.slice(1);
                }
            }
        }
        else{
            if(inputElements[0].value.includes('-') || inputElements[0].value.includes('e')){
                inputElements[0].value = "";
            }
            if(inputElements[1].value.includes('-') || inputElements[1].value.includes('e')){
                inputElements[1].value = "";
            }
            if(inputElements[2] != null && inputElements[3] != null && inputElements[2].type == "number" && inputElements[3].type == "number"){
                if(inputElements[2].value.includes('-') || inputElements[3].value.includes('e')){
                    inputElements[2].value = "";
                }

                if(inputElements[3].value.includes('-') || inputElements[3].value.includes('e')){
                    inputElements[3].value = "";
                }
            }
        }

        if(inputElements[0].value == ""){
            inputElements[0].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("Please enter value for Price!");}
        }
        else if(Number(inputElements[0].value) < Number(inputElements[0].min)){
            inputElements[0].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("The value entered for Price must be greater than or equal to " + inputElements[0].min + "!");}
        }
        else if(!valueControlWithDigitLimit(inputElements[0].value, inputInfo_maxBeforePlaces)){
            inputElements[0].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("The integer part of the value you enter for Price cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
        }

        if(inputElements[1].value == ""){
            inputElements[1].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("Please enter value for Count!");}
        }
        else if(Number(inputElements[1].value) < Number(inputElements[1].min)){
            inputElements[1].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("The value entered for Count must be greater than or equal to " + inputElements[1].min + "!");}
        }
        else if(!valueControlWithDigitLimit(inputElements[1].value, inputInfo_maxBeforePlaces)){
            inputElements[1].classList.add("danger");
            formvalid = false;
            if(formpost){toast.error("The integer part of the value you enter for Count cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
        }
        
        if(formvalid && inputElements[2] != null && inputElements[3] != null && inputElements[2].type == "number" && inputElements[3].type == "number"){//price ve count uygunsa, stop loss ve take profit değerleri var ise kontrol edilecek
            if(parseFloat(inputElements[0].value) <= parseFloat(inputElements[2].value)){//stop loss için
                inputElements[2].classList.add("danger");
                if(formpost){toast.error("Stop Loss value must be less than Asking Price!");}
                formvalid = false;
            }
            else if(!valueControlWithDigitLimit(inputElements[2].value, inputInfo_maxBeforePlaces)){
                inputElements[2].classList.add("danger");
                formvalid = false;
                if(formpost){toast.error("The integer part of the value you enter for Stop Loss cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
            }
            else if(0 >= parseFloat(inputElements[2].value)){
                inputElements[2].classList.add("danger");
                if(formpost){toast.error("Stop Loss value must be bigger than 0!");}
                formvalid = false;
            }

            if(parseFloat(inputElements[0].value) >= parseFloat(inputElements[3].value)){//take profit için
                inputElements[3].classList.add("danger");
                if(formpost){toast.error("Take Profit value must be bigger than Asking Price!");}
                formvalid = false;
            }
            else if(!valueControlWithDigitLimit(inputElements[3].value, inputInfo_maxBeforePlaces)){
                inputElements[3].classList.add("danger");
                formvalid = false;
                if(formpost){toast.error("The integer part of the value you enter for Take Profit cannot exceed " + inputInfo_maxBeforePlaces + " digits!");}
            }
            else if(0 >= parseFloat(inputElements[3].value)){
                inputElements[3].classList.add("danger");
                if(formpost){toast.error("Take Profit value must be bigger than 0!");}
                formvalid = false;
            }
        }

        if(formvalid && formpost){//uygunsa post edilecek
            const data = new FormData(form);
            const updateData = Object.fromEntries(data);

            const keysToCheck = ['asking_price', 'count', 'stop_loss', 'take_profit'];
            let isDifferent = false;

            for (let key of keysToCheck) {
                let newUpdateValue = updateData[key];
                let currentValue = currentEditOrder[key];

                if(newUpdateValue == "" || newUpdateValue == undefined){
                    newUpdateValue = null;
                }
                if(currentValue == "" || currentValue == undefined){
                    currentValue = null;
                }

                if (newUpdateValue != currentValue && (newUpdateValue != null || currentValue != null)) {
                    isDifferent = true;
                    break;
                }
            }

            if(isDifferent){//popup içindeki bilgiler değiştirilmiş mi
                const session = await Auth.currentSession();
                const jwtToken = session.getIdToken().getJwtToken();

                axios.post('https://api.kpricemarket.com/spotorder/update', 
                    JSON.stringify(updateData),
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                )
                .then(function (response) {
                    if(response.status == "200" && response.data.message == "Data updated successfully"){
                        toast.success("Order updated successfully!");
                        orderHistoryTableUpdate(response.data.data);
                    }
                    else{
                        toast.error(`There was a problem updating the order!${response.data.message ? ` (${response.data.message})` : ""}`);
                    }
                    setSubmitOrderUpdateLoading(false);
                })
                .catch(function (error) {
                    toast.error(`There was a problem updating the order!${error.response.data.error ? ` (${error.response.data.error})` : ""}`);
                    setSubmitOrderUpdateLoading(false);
                });
            }
            else{
                setSubmitOrderUpdateLoading(false);
            }
            setDisplayEditDialog(false);
        }
        else{
            setSubmitOrderUpdateLoading(false);
        }
    }

    const orderHistoryTableUpdateAnimation = (orderid) => {
        let visibleRows = document.getElementsByClassName("MuiTableRow-root");//tabloda görünen veriler
        for (let i = 0; i < visibleRows.length; i++) {
            if(visibleRows[i].innerHTML.includes(orderid)){
                let updatedRow = visibleRows[i];
                updatedRow.style.animation = "close-animation 1s";
                updatedRow.addEventListener('animationend', () => {
                    updatedRow.style.animation = "";
                });
            }
        }
    }

    const [triggerAnimation, setTriggerAnimation] = useState(null);

    useEffect(() => {
        if (triggerAnimation) {
            orderHistoryTableUpdateAnimation(triggerAnimation);
            setTriggerAnimation(null);
        }
    }, [triggerAnimation]);

    const orderHistoryTableUpdate = (newOrder) => {
        if(newOrder != undefined && Object.keys(newOrder).length != 0){
            let isOrderUpdate = false;//bulunan order'ın state'i ile gelen bilginin state'i eşit ise bulunan veri güncellenecek mi diye karşılaştırılsın
            let newDataState = newOrder.state != undefined ? newOrder.state : null;

            if(newDataState){//new order içinde date bilgisi gelmezse burada oluşturuyoruz
                if(newOrder.entry_date == undefined && newOrder.entry_timestamp != undefined && (newDataState == "waiting" || newDataState == "processing")){
                    newOrder["entry_date"] = moment(Number(newOrder.entry_timestamp)).tz(timeZone).format("YYYY-MM-DD HH:mm");
                }
                else if(newOrder.result_date == undefined && newOrder.result_timestamp != undefined && (newDataState == "approved" || newDataState == "denied")){
                    newOrder["result_date"] = moment(Number(newOrder.result_timestamp)).tz(timeZone).format("YYYY-MM-DD HH:mm");
                }
            }

            let existInOpen = false;//open içinde var mı
            let existInOpenIndex = null;//open içinde varsa index'i
            let existInOpenState = null;//open içinde varsa state'i
            
            let existInClosed = false;//closed içinde var mı
            let existInClosedIndex = null;//closed içinde varsa index'i
            let existInClosedState = null;//closed içinde varsa state'i

            for (let i = openOrders.length - 1; i >= 0; i--) {//open içinde var mı
                if (openOrders[i].order_id == newOrder.order_id) {
                    existInOpen = true;
                    existInOpenIndex = i;
                    existInOpenState = openOrders[i].state;
                }
            }

            for (let i = closedOrders.length - 1; i >= 0; i--) {//closed içinde var mı
                if (closedOrders[i].order_id == newOrder.order_id) {
                    existInClosed = true;
                    existInClosedIndex = i;
                    existInClosedState = closedOrders[i].state;
                }
            }

            //silme işlemleri
            if(existInOpen){//open içinde var ise
                if(existInOpenState == newDataState){
                    isOrderUpdate = true;
                }
                else{
                    if(!(existInOpenState == "processing" && newDataState == "waiting")){//open içinde processing var ama yeni veri waiting olarak gelmiş ise silmiyoruz, bu durum dışındakiler silinecek
                        if(newDataState == null || newDataState == "cancelled"){//animasyon için cancelled bilgilerde 1 saniye bekletiyoruz
                            setTriggerAnimation(newOrder.order_id);
                            setTimeout(() => {
                                setOpenOrders(prevOrders => [
                                    ...prevOrders.slice(0, existInOpenIndex),
                                    ...prevOrders.slice(existInOpenIndex + 1)
                                ]);
                            }, 1000);
                        }
                        else{
                            setOpenOrders(prevOrders => [
                                ...prevOrders.slice(0, existInOpenIndex),
                                ...prevOrders.slice(existInOpenIndex + 1)
                            ]);
                        }
                        existInOpen = false;
                    }
                }
            }
            else if(existInClosed){//closed içinde var ise
                if(existInClosedState == newDataState){
                    isOrderUpdate = true;
                }
                else{
                    if(newDataState != "waiting" && newDataState != "processing"){//waiting ve processing gelmediyse siliyoruz
                        if(newDataState == null || newDataState == "cancelled"){//animasyon için cancelled bilgilerde 1 saniye bekletiyoruz
                            setTriggerAnimation(newOrder.order_id);
                            setTimeout(() => {
                                setClosedOrders(prevOrders => [
                                    ...prevOrders.slice(0, existInClosedIndex),
                                    ...prevOrders.slice(existInClosedIndex + 1)
                                ]);
                            }, 1000);
                        }
                        else{
                            setClosedOrders(prevOrders => [
                                ...prevOrders.slice(0, existInClosedIndex),
                                ...prevOrders.slice(existInClosedIndex + 1)
                            ]);
                        }
                        existInClosed = false;
                    }
                }
            }

            //karşılaştırmaya göre silme işlemleri
            if(isOrderUpdate){//order id var ve yeni gelen datanın state bilgisiyle aynı. o yüzden güncelleme yapılacak mı kontrol edilecek.
                const keysToCheck = existInOpen ? 
                ['asking_price', 'count', 'customer_id', 'entry_timestamp', 'product_id', 'state', 'stop_loss', 'take_profit', 'type']
                : ['asking_price', 'count', 'currency_fee', 'customer_id', 'processing_price', 'product_id', 'realized_count', 'result_timestamp', 'state', 'to_currency_fee', 'total', 'type'];
                let isDifferent = false;
                let controlValue = existInOpen ? openOrders[existInOpenIndex] : closedOrders[existInClosedIndex];
                
                for (let key of keysToCheck) {
                    let newOrderValue = newOrder[key];
                    let tableDataValue = controlValue[key];

                    if(newOrderValue == "" || newOrderValue == undefined){
                        newOrderValue = null;
                    }
                    if(tableDataValue == "" || tableDataValue == undefined){
                        tableDataValue = null;
                    }

                    if(newOrderValue != tableDataValue && (newOrderValue != null || tableDataValue != null)){
                        isDifferent = true;
                        break;
                    }
                }

                if(isDifferent){
                    if(existInOpen){
                        setOpenOrders(prevOrders => [
                            ...prevOrders.slice(0, existInOpenIndex),
                            ...prevOrders.slice(existInOpenIndex + 1)
                        ]);
                        existInOpen = false;
                    }
                    else if(existInClosed){
                        setClosedOrders(prevOrders => [
                            ...prevOrders.slice(0, existInClosedIndex),
                            ...prevOrders.slice(existInClosedIndex + 1)
                        ]);
                        existInClosed = false;
                    }
                }
            }

            //ekleme işlemleri
            if(!existInOpen && !existInClosed && (newDataState == "waiting" || newDataState == "processing")){//hem open hem closed içinde yok ise ve waiting ya da processing geldiyse
                setOpenOrders(prevOrders => [...prevOrders, newOrder]);
                setTriggerAnimation(newOrder.order_id);
            }
            else if(!existInClosed && (newDataState == "approved" || newDataState == "denied")){
                setClosedOrders(prevOrders => [...prevOrders, newOrder]);
                setTriggerAnimation(newOrder.order_id);
            }
        }
    }

    //order bilgilerinin kontrolleri - başlangıç
    const orderDataControl_Market = (product_id) => {
        const currency = product_id.split("-")[0];
        if(currenciesData && currenciesData[currency] && currenciesData[currency].picture_url){
            return <div className="market"><img src={currenciesData[currency].picture_url} /><span>{product_id}</span></div>;
        }
        return <div className="market"><span>{product_id}</span></div>;
    }

    const orderDataControl_Type = (type) => {
        if (type === "limitbuy") {
            return "Limit Buy";
        } else if (type === "limitsell" || type === "stoploss" || type === "takeprofit") {
            return "Limit Sell";
        } else if (type === "marketbuy") {
            return "Market Buy";
        } else if (type === "marketsell") {
            return "Market Sell";
        } else{
            return "";
        }
    }

    const orderDataControl_StopLoss = (type, order, forSorting, show_places) => {
        if(type == "Limit Buy" || type == "Market Buy"){
            if(order.stop_loss == "" || order.stop_loss == null || order.stop_loss == undefined){
                return forSorting ? 0 : "";
            } else{
                if(forSorting){
                    return Number(order.stop_loss);
                }
                else{
                    const response = formatValuePriceControl(order.stop_loss, show_places);
                    if(response){
                        return formatValue3(order.stop_loss, show_places);
                    }
                    else{
                        return formatValue4(order.stop_loss);
                    }
                }
            }
        } else{
            return forSorting ? 0 : "";
        }
    }

    const orderDataControl_TakeProfit = (type, order, forSorting, show_places) => {
        if(type == "Limit Buy" || type == "Market Buy"){
            if(order.take_profit == "" || order.take_profit == null || order.take_profit == undefined){
                return forSorting ? 0 : "";
            } else{
                if(forSorting){
                    return Number(order.take_profit);
                }
                else{
                    const response = formatValuePriceControl(order.take_profit, show_places);
                    if(response){
                        return formatValue3(order.take_profit, show_places);
                    }
                    else{
                        return formatValue4(order.take_profit);
                    }
                }
            }
        } else{
            return forSorting ? 0 : "";
        }
    }

    const orderDataControl_TypeClass = (type) => {
        if(type === "Limit Buy" || type === "Market Buy"){
            return "success";
        } else{
            return "danger";
        }
    }

    const orderDataControl_TypeForTable = (type, typeclass) => {
        if(type === "Limit Buy" || type === "Limit Sell"){
            return <span className={typeclass}>Limit</span>;
        } else{
            return <span className={typeclass}>Market</span>;
        }
    }

    const orderDataControl_State_Open = (order, i) => {
        if(order.state === "waiting"){
            if(order.type === "limitbuy" || order.type === "limitsell" || order.type === "stoploss" || order.type === "takeprofit"){
                return <span data-order_id={order.order_id} className="openOrderState"><EditIconForm order={order} index={i} /><label className="warning">Waiting</label><CancelIconForm order={order} index={i} /></span>;
            }
            else{
                return <span data-order_id={order.order_id} className="openOrderState"><label className="warning noedit">Waiting</label><CancelIconForm order={order} index={i} /></span>;
            }
        } else if(order.state === "processing"){
            return <span data-order_id={order.order_id}><label className="success nobuttons">Processing</label></span>;
        } else{
            return "";
        }
    }

    const orderDataControl_State_Close = (order) => {
        if(order.state === "approved"){
            return <span data-order_id={order.order_id}><label className="success">Approved</label></span>;
        } else if(order.state === "denied"){
            return <span data-order_id={order.order_id}><label className="danger">Denied</label></span>;
        } else{
            return "";
        }
    }

    const orderDataControl_Price = (price, show_places) => {
        if(price != "" && price != null && price != undefined){
            const response = formatValuePriceControl(price, show_places);
            if(response){
                return formatValue3(price, show_places);
            }
            else{
                return formatValue4(price);
            }
        }
        return "";
    }

    const orderDataControl_Total = (price, show_places) => {
        let editedValue = price != "" && price != null && price != undefined ? formatValue3(price, show_places) : "";
        let fullValue = price != "" && price != null && price != undefined ? formatValue4(price) : "";

        return <div className="total-value">
                    ≈ {editedValue}
                    <Tooltip title={fullValue} placement="top">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>;
    }

    //mobil görünüm için
    const orderDataMobileControl_Market = (order) => {
        if(order){
            const currency = order.product_id.split("-")[0];
            let type = "";
            let typeClass = "";
            if (order.type === "limitbuy") {
                type = "Limit Buy";
            } else if (order.type === "limitsell" || order.type === "stoploss" || order.type === "takeprofit") {
                type = "Limit Sell";
            } else if (order.type === "marketbuy") {
                type = "Market Buy";
            } else if (order.type === "marketsell") {
                type = "Market Sell";
            } else{
                type = "";
            }

            if(type === "Limit Buy" || type === "Market Buy"){
                typeClass = "success";
            } else{
                typeClass = "danger";
            }

            return <div className="mobile-column-market">
                <div className="market">
                    {currenciesData && currenciesData[currency] && currenciesData[currency].picture_url && <img src={currenciesData[currency].picture_url} />}
                    <div>
                        <span>{order.product_id}</span>
                        {type === "Limit Buy" || type === "Limit Sell" ? <span className={typeClass}>Limit</span> : <span className={typeClass}>Market</span>}
                    </div>
                </div>
                <div className="date">
                    {order.entry_date ? order.entry_date : order.result_date}
                </div>
            </div>;
        }
        return "";
    }

    const orderDataMobileControl_Price_Open = (order) => {
        if(order){
            const productMaxOrderAskingPricePlaces = productData && productData[order.product_id] && productData[order.product_id].max_order_asking_price_places ? productData[order.product_id].max_order_asking_price_places : null;
            
            let editedValue = null;
            if(order.asking_price != "" && order.asking_price != null && order.asking_price != undefined){
                const response = formatValuePriceControl(order.asking_price, productMaxOrderAskingPricePlaces);
                if(response){
                    editedValue = formatValue3(order.asking_price, productMaxOrderAskingPricePlaces);
                }
                else{
                    editedValue = formatValue4(order.asking_price);
                }
            }

            return <div className="mobile-column-price">
                <div className="price">
                    <label>Price:</label>
                    <span>{editedValue}</span>
                </div>
                <div className="count">
                    <label>Count:</label>
                    <span>{order.count != "" && order.count != null && order.count != undefined ? formatValue4(order.count) : ""}</span>
                </div>
            </div>;
        }
        return "";
    }

    const orderDataMobileControl_State_Open = (order, i) => {
        if(order){
            if(order.state === "waiting"){
                if(order.type === "limitbuy" || order.type === "limitsell" || order.type === "stoploss" || order.type === "takeprofit"){
                    return <span data-order_id={order.order_id} className="mobile-column-state"><EditIconForm order={order} index={i} /><i className="fa-solid fa-clock warning"></i><CancelIconForm order={order} index={i} /></span>;
                }
                else{
                    return <span data-order_id={order.order_id} className="mobile-column-state"><i className="fa-solid fa-clock warning noedit"></i><CancelIconForm order={order} index={i} /></span>;
                }
            } else if(order.state === "processing"){
                return <span data-order_id={order.order_id}><i className="fa-solid fa-clock success nobuttons"></i></span>;
            } else{
                return "";
            }
        }
        return "";
    }

    const orderDataMobileControl_Price_Close = (order) => {
        if(order){
            const productMaxOrderAskingPricePlaces = productData && productData[order.product_id] && productData[order.product_id].max_order_asking_price_places ? productData[order.product_id].max_order_asking_price_places : null;
            const secondCurrencyShowPlaces = currenciesData && currenciesData[order.product_id.split("-")[1]] && currenciesData[order.product_id.split("-")[1]].show_places ? currenciesData[order.product_id.split("-")[1]].show_places : null;
            
            let currentValue = order.state == "approved" ? order.processing_price : order.asking_price;

            let editedValue = null;
            if(currentValue != "" && currentValue != null && currentValue != undefined){
                const response = formatValuePriceControl(currentValue, productMaxOrderAskingPricePlaces);
                if(response){
                    editedValue = formatValue3(currentValue, productMaxOrderAskingPricePlaces);
                }
                else{
                    editedValue = formatValue4(currentValue);
                }
            }

            return <div className="mobile-column-price">
                <div className="price">
                    <label>Price:</label>
                    <span>{editedValue}</span>
                </div>
                <div className="count">
                    <label>Count:</label>
                    <span>{order.state == "approved" && order.realized_count != "" && order.realized_count != null && order.realized_count != undefined && order.count != "" && order.count != null && order.count != undefined ? `${formatValue4(order.realized_count)}/${formatValue4(order.count)}` : (order.count != "" && order.count != null && order.count != undefined ? formatValue4(order.count) : "")}</span>
                </div>
                <div className="total">
                    <label>Total:</label>
                    <span>{order.state == "approved" && order.total != "" ? orderDataControl_Total(order.total, secondCurrencyShowPlaces) : ""}</span>
                </div>
            </div>;
        }
        return "";
    }

    const orderDataMobileControl_State_Close = (order, i) => {
        if(order){
            if(order.state === "approved"){
                return <span data-order_id={order.order_id} className="mobile-column-state"><i className="fa-solid fa-circle-check success"></i></span>;
            } else if(order.state === "denied"){
                return <span data-order_id={order.order_id} className="mobile-column-state"><i className="fa-solid fa-circle-xmark danger"></i></span>;
            } else{
                return "";
            }
        }
        return "";
    }
    //order bilgilerinin kontrolleri - son

    const tableDataOpen = useMemo(() => {//open orders verileri
        if (!openOrders.length) return [];
    
        const newData = [];
        openOrders.forEach((order, i) => {
            const productMaxOrderAskingPricePlaces = productData && productData[order.product_id] && productData[order.product_id].max_order_asking_price_places ? productData[order.product_id].max_order_asking_price_places : null;
            let marketText = orderDataControl_Market(order.product_id);
            let typetext = orderDataControl_Type(order.type);
            let stoplosscell = orderDataControl_StopLoss(typetext, order, true, null);
            let takeprofitcell = orderDataControl_TakeProfit(typetext, order, true, null);
            
            let typeclass = orderDataControl_TypeClass(typetext);
            let typeForTable = orderDataControl_TypeForTable(typetext, typeclass);
            let stateText = orderDataControl_State_Open(order, i);

            let priceCell = orderDataControl_Price(order.asking_price, productMaxOrderAskingPricePlaces);
            
            newData.push({
                order_id: order.order_id,
                date: order.entry_date,
                timestamp: order.entry_timestamp,
                market: order.product_id,
                marketForTable: marketText,
                type: typetext,
                typeForTable: typeForTable,
                price: order.asking_price,
                priceForTable: priceCell,
                count: order.count != "" && order.count != null && order.count != undefined ? formatValue4(order.count) : "NaN",
                stop_loss: stoplosscell,
                stop_lossForTable: orderDataControl_StopLoss(typetext, order, false, productMaxOrderAskingPricePlaces),
                take_profit: takeprofitcell,
                take_profitForTable: orderDataControl_TakeProfit(typetext, order, false, productMaxOrderAskingPricePlaces),
                state: stateText
            });
        });
    
        return newData;
    }, [openOrders, orderHistory, productData]);

    const tableDataClosed = useMemo(() => {//closed orders verileri
        if (!closedOrders.length) return [];
    
        const newData = [];
        closedOrders.forEach((order, i) => {
            if(order.state != "cancelled"){
                const productMaxOrderAskingPricePlaces = productData && productData[order.product_id] && productData[order.product_id].max_order_asking_price_places ? productData[order.product_id].max_order_asking_price_places : null;
                const secondCurrencyShowPlaces = currenciesData && currenciesData[order.product_id.split("-")[1]] && currenciesData[order.product_id.split("-")[1]].show_places ? currenciesData[order.product_id.split("-")[1]].show_places : null;
                let marketText = orderDataControl_Market(order.product_id);
                let typetext = orderDataControl_Type(order.type);
                
                let typeclass = orderDataControl_TypeClass(typetext);
                let typeForTable = orderDataControl_TypeForTable(typetext, typeclass);
                let stateText = orderDataControl_State_Close(order);

                let pricetext = order.state == "approved" ? order.processing_price : order.asking_price;
                let pricetextForTable = order.state == "approved" ? orderDataControl_Price(order.processing_price, productMaxOrderAskingPricePlaces) : orderDataControl_Price(order.asking_price, productMaxOrderAskingPricePlaces);
                let counttext = order.state == "approved" && order.realized_count != "" && order.realized_count != null && order.realized_count != undefined && order.count != "" && order.count != null && order.count != undefined ? `${formatValue4(order.realized_count)}/${formatValue4(order.count)}` : (order.count != "" && order.count != null && order.count != undefined ? formatValue4(order.count) : "");
                let totaltext = order.state == "approved" && order.total != "" ? order.total : 0;
                let totaltextForTable = order.state == "approved" && order.total != "" ? orderDataControl_Total(order.total, secondCurrencyShowPlaces) : "";
    
                newData.push({
                    order_id: order.order_id,
                    date: order.result_date,
                    timestamp: order.result_timestamp,
                    market: order.product_id,
                    marketForTable: marketText,
                    type: typetext,
                    typeForTable: typeForTable,
                    price: pricetext,
                    priceForTable: pricetextForTable,
                    count: counttext,
                    total: totaltext,
                    totalForTable: totaltextForTable,
                    state: stateText
                });
            }
        });
    
        return newData;
    }, [closedOrders, orderHistory, productData, currenciesData]);

    const tableDataOpenMobile = useMemo(() => {//open orders verileri
        if (!openOrders.length) return [];
    
        const newData = [];
        openOrders.forEach((order, i) => {
            let marketText = orderDataMobileControl_Market(order);
            let priceText = orderDataMobileControl_Price_Open(order);
            let stateText = orderDataMobileControl_State_Open(order, i);
            
            newData.push({
                timestamp: order.entry_timestamp,
                market: marketText,
                price: priceText,
                state: stateText
            });
        });
    
        return newData;
    }, [openOrders, orderHistory, productData]);

    const tableDataClosedMobile = useMemo(() => {//open orders verileri
        if (!closedOrders.length) return [];
    
        const newData = [];
        closedOrders.forEach((order, i) => {
            let marketText = orderDataMobileControl_Market(order);
            let priceText = orderDataMobileControl_Price_Close(order);
            let stateText = orderDataMobileControl_State_Close(order, i);
            
            newData.push({
                timestamp: order.result_timestamp,
                market: marketText,
                price: priceText,
                state: stateText
            });
        });
    
        return newData;
    }, [closedOrders, orderHistory, productData, currenciesData]);

    useEffect(() => {//order history tablosu için yeni bilgi geldiğinde yapılanlar
        if (isFirstRenderForNewData.current) {
            isFirstRenderForNewData.current = false;
            return;
        }
        orderHistoryTableUpdate(orderHistoryNewData);
    }, [orderHistoryNewData]);

    useEffect(() => {
        if (newOrderFromBuySell) {
            orderHistoryTableUpdate(newOrderFromBuySell);
        }
    }, [newOrderFromBuySell]);

    useEffect(() => {//listener için
        const checkScreenWidth = () => {
            if(window.innerWidth <= 800){
                setViewType("mobile");
            }
            else{
                setViewType("pc");
            }
        }

        checkScreenWidth();
        
        window.addEventListener('resize', checkScreenWidth);

        //closed orders time select görünürlüğü dışarıya tıklandığında otomatik kapanması için
        document.addEventListener('click', hiddenCustomizedSelect);
        return () => {//component kapanırken event listener temizleniyor
          document.removeEventListener('click', hiddenCustomizedSelect);
          window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    const hiddenCustomizedSelect = (e) => {//closed orders time select görünürlüğü dışarıya tıklandığında otomatik kapanması için
        if (closedOrdersTimeSelect.current && !closedOrdersTimeSelect.current.contains(e.target) && closedOrdersTimeSelectMenu.current && !closedOrdersTimeSelectMenu.current.contains(e.target)) {
            setClosedOrdersTimeSelectDisplay(false);
        }
    };

    //min, step değerlerinin düzenlenecek order'ın product_id bilgisine göre güncellenmesi
    useEffect(() => {
        let currentProductId = currentEditOrder && currentEditOrder.product_id ? currentEditOrder.product_id : null;

        if(currentProductId && productData && productData[currentProductId]){
            setInputNumberMinValue(productData[currentProductId].min_order_count);
            setInputInfo_askingPricePlaces(productData[currentProductId].max_order_asking_price_places);
            setInputInfo_countPlaces(productData[currentProductId].max_order_count_places);
            setInputInfo_maxBeforePlaces(productData[currentProductId].max_before_places);
        }
        else{
            setInputNumberMinValue(0);
            setInputInfo_askingPricePlaces(0);
            setInputInfo_countPlaces(0);
            setInputInfo_maxBeforePlaces(0);
        }
    }, [currentEditOrder]);

    useEffect(() => {
        if(!displayEditDialog){
            setCurrentEditOrder(null);
        }
    }, [displayEditDialog]);

    useEffect(() => {
        if(!displayConfirm){
            setCurrentDeleteOrderId("");
        }
    }, [displayConfirm]);

    return (
        <div className="allsections allsections-border section-tradeorderhistory">
            <ConfirmDialog 
                visible={displayConfirm}
                draggable={false}
                resizable={false}
                onHide={() => setDisplayConfirm(false)}
                message="Are you sure you want to cancel the order?"
                header="Cancel Order"
                icon="pi pi-info-circle"
                accept={() => cancelIconClick(currentDeleteOrderId)}
                reject={() => setDisplayConfirm(false)}
            />
            <Dialog header="Edit Order" visible={displayEditDialog} draggable={false} resizable={false} className="edit-order-dialog" onHide={() => setDisplayEditDialog(false)}>
                {currentEditOrder ? 
                <form id="edit-order-form" className="edit-order-form">
                    <span>Price</span>
                    <input type="number" id="edit-spot-order-form-input-price" defaultValue={getValueWithShowPlaces(inputInfo_askingPricePlaces, currentEditOrder.asking_price)} name="asking_price" step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} placeholder="Price" onChange={(event) => {setInputValueByShowPlacesFunction(inputInfo_askingPricePlaces, event.target);editDialogUpdate(false, event.target);}} />
                    <label onClick={() => document.getElementById("edit-spot-order-form-input-price").focus()}>{currentEditOrder.product_id ? currentEditOrder.product_id.split("-")[1] : "NaN"}</label>
                    <span>Count</span>
                    <input type="number" id="edit-spot-order-form-input-count" defaultValue={getValueWithShowPlaces(inputInfo_countPlaces, currentEditOrder.count)} name="count" step={getMinAndStepWithShowPlaces(inputInfo_countPlaces)} min={inputNumberMinValue} placeholder="Count" onChange={(event) => {setInputValueByShowPlacesFunction(inputInfo_countPlaces, event.target);editDialogUpdate(false, event.target);}} />
                    <label onClick={() => document.getElementById("edit-spot-order-form-input-count").focus()}>{currentEditOrder.product_id ? currentEditOrder.product_id.split("-")[0] : "NaN"}</label>
                    {currentEditOrder.type == "limitbuy" ? 
                    <div>
                        <span>Stop Loss</span>
                        <i className="fa-solid fa-plus plusicon" id="edit-order-form-plus1" onClick={() => {document.getElementById("edit-order-form-input1").focus();}} style={currentEditOrder.stop_loss ? {display: "none"} : {undefined}}></i>
                        <input type="number" id="edit-order-form-input1" defaultValue={getValueWithShowPlaces(inputInfo_askingPricePlaces, currentEditOrder.stop_loss)} name={currentEditOrder.stop_loss ? "stop_loss" : undefined} className={currentEditOrder.stop_loss ? undefined : "locked"} onBlur={(event) => {if(event.target.value == ""){document.getElementById("edit-order-form-plus1").style.display="block";event.target.name="";event.target.classList.add("locked");}else{event.target.name="stop_loss";event.target.classList.remove("locked");}}} onFocus={(event) => {document.getElementById("edit-order-form-plus1").style.display="none";event.target.classList.remove("locked");}} onChange={(event) => {setInputValueByShowPlacesFunction(inputInfo_askingPricePlaces, event.target);editDialogUpdate(false, event.target);}} step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} />
                        <label onClick={() => document.getElementById("edit-order-form-input1").focus()}>{currentEditOrder.product_id ? currentEditOrder.product_id.split("-")[1] : "NaN"}</label>
                        <span>Take Profit</span>
                        <i className="fa-solid fa-plus plusicon" id="edit-order-form-plus2" onClick={() => {document.getElementById("edit-order-form-input2").focus();}} style={currentEditOrder.take_profit ? {display: "none"} : {undefined}}></i>
                        <input type="number" id="edit-order-form-input2" defaultValue={getValueWithShowPlaces(inputInfo_askingPricePlaces, currentEditOrder.take_profit)} name={currentEditOrder.take_profit ? "take_profit" : undefined} className={currentEditOrder.take_profit ? undefined : "locked"} onBlur={(event) => {if(event.target.value == ""){document.getElementById("edit-order-form-plus2").style.display="block";event.target.name="";event.target.classList.add("locked");}else{event.target.name="take_profit";event.target.classList.remove("locked");}}} onFocus={(event) => {document.getElementById("edit-order-form-plus2").style.display="none";event.target.classList.remove("locked");}} onChange={(event) => {setInputValueByShowPlacesFunction(inputInfo_askingPricePlaces, event.target);editDialogUpdate(false, event.target);}} step={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} min={getMinAndStepWithShowPlaces(inputInfo_askingPricePlaces)} />
                        <label onClick={() => document.getElementById("edit-order-form-input2").focus()}>{currentEditOrder.product_id ? currentEditOrder.product_id.split("-")[1] : "NaN"}</label>
                    </div> 
                    : null}
                    <input type="hidden" value={currentEditOrder.order_id} name="order_id" />
                    <input type="hidden" value="modify" name="update_type"/>
                    <button type="button" onClick={() => editDialogUpdate(true, null)} disabled={submitOrderUpdateLoading}>{submitOrderUpdateLoading ? <Loading /> : "Update"}</button>
                </form> 
                : null}
            </Dialog>
            <div className="section-tradeorderhistory-options">
                <div>
                    <span className={activeOption == "Open Orders" ? "active" : undefined} onClick={() => setActiveOption("Open Orders")}>Open Orders</span>
                    <span className={activeOption == "Closed Orders" ? "active" : undefined} onClick={() => setActiveOption("Closed Orders")}>Closed Orders</span>
                </div>
                <div className="section-tradeorderhistory-options-select" ref={closedOrdersTimeSelect} style={activeOption == "Closed Orders" ? {} : {visibility: "hidden"}} onClick={() => {if(closedOrdersTimeSelectDisplay){setClosedOrdersTimeSelectDisplay(false)}else{setClosedOrdersTimeSelectDisplay(true)}}}>
                    {closedOrdersTime == "last1w" ? "1 Week" : (closedOrdersTime == "last1mo" ? "1 Month" : (closedOrdersTime == "last3mo" ? "3 Month" : (closedOrdersTime == "last6mo" ? "6 Month" : "1 Year")))}
                    <i className="fa-solid fa-caret-up" style={closedOrdersTimeSelectDisplay ? {marginTop: "2px"} : {display: "none"}}></i>
                    <i className="fa-solid fa-caret-down" style={closedOrdersTimeSelectDisplay ? {display: "none"} : {}}></i>
                </div>
                <div className="section-tradeorderhistory-options-select-menu" ref={closedOrdersTimeSelectMenu} style={closedOrdersTimeSelectDisplay ? {display: "block"} : {display: "none"}}>
                    <label className={closedOrdersTime == "last1w" ? "active" : undefined} onClick={() => {setClosedOrdersTime("last1w");setClosedOrdersTimeSelectDisplay(false);}}>1 Week</label>
                    <label className={closedOrdersTime == "last1mo" ? "active" : undefined} onClick={() => {setClosedOrdersTime("last1mo");setClosedOrdersTimeSelectDisplay(false);}}>1 Month</label>
                    <label className={closedOrdersTime == "last3mo" ? "active" : undefined} onClick={() => {setClosedOrdersTime("last3mo");setClosedOrdersTimeSelectDisplay(false);}}>3 Month</label>
                    <label className={closedOrdersTime == "last6mo" ? "active" : undefined} onClick={() => {setClosedOrdersTime("last6mo");setClosedOrdersTimeSelectDisplay(false);}}>6 Month</label>
                    <label className={closedOrdersTime == "last1y" ? "active" : undefined} onClick={() => {setClosedOrdersTime("last1y");setClosedOrdersTimeSelectDisplay(false);}}>1 Year</label>
                </div>
            </div>
            <div ref={orderHistoryArea} className={isOrderHistoryLoading != false ? "section-tradeorderhistory-list loading" : "section-tradeorderhistory-list"}>
                {isOrderHistoryLoading != false ?
                <Loading /> :
                <>
                    {activeOption == "Open Orders" ? 
                        (viewType == "pc" ? 
                            <div className="column8 openClosedOrders">
                                <MaterialReactTable columns={columnsOpen} data={tableDataOpen} enablePagination={false} initialState={{sorting: [{id: 'timestamp', desc: true}]}} />
                            </div> : 
                            <div className="column3 mobile-table openClosedOrders">
                                <MaterialReactTable columns={columnsOpenMobile} data={tableDataOpenMobile} enablePagination={false} initialState={{sorting: [{id: 'timestamp', desc: true}]}} />
                            </div>) 
                    : undefined}
                    {activeOption == "Closed Orders" ? 
                        (viewType == "pc" ? 
                        <div className="column7 openClosedOrders">
                            <MaterialReactTable columns={columnsClosed} data={tableDataClosed} enablePagination={false} initialState={{sorting: [{id: 'timestamp', desc: true}]}} />
                        </div> :
                        <div className="column3 mobile-table openClosedOrders">
                            <MaterialReactTable columns={columnsClosedMobile} data={tableDataClosedMobile} enablePagination={false} initialState={{sorting: [{id: 'timestamp', desc: true}]}} />
                        </div>)
                    : undefined}
                </>
                }
            </div>
        </div>
    );
};

export default withLoadingData()(React.memo(SectionTradeOrderHistory, isEqual));
