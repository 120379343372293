import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatValuePriceControl, formatValue1, formatValue3, formatValue4, getPriceForWalletCurrencies, getPriceForCurrency } from '../Lib/calculationFunctions';
import { Link } from 'react-router-dom';

const SectionCurrencyInfoPopupContent = ({ currency, setDisplayCurrencyDialog, setDisplayTransferDialog, setDisplayTransactionHistoryDialog }) => {
    
    const currenciesData = useSelector((state) => state.kprice.currencies.data);
    const productsData = useSelector((state) => state.kprice.products.data);
    const productsMiniTickerData = useSelector((state) => state.kprice.productsMiniTicker.data);
    const walletData = useSelector((state) => state.kprice.wallet.data);

    const [currentPriceForCurrency, setCurrentPriceForCurrency] = useState("NaN");

    const [goTradeProducts, setGoTradeProducts] = useState([]);

    const calcPrice = (price, show_places) => {
        const response = formatValuePriceControl(price, show_places);
        if(response){
            return formatValue3(price, show_places);
        }
        else{
            return formatValue4(price);
        }
    }

    const calcPriceForCurrencies = (key) => {
        let currentPrice = key && productsMiniTickerData ? productsMiniTickerData[key].currentPrice : "NaN";
        let show_places = key && productsData && productsData[key] && productsData[key].show_places ? productsData[key].show_places : null;
        
        return calcPrice(currentPrice, show_places);
    }

    useEffect(() => {
        let currentPrice = currency && productsMiniTickerData ? getPriceForCurrency(currency, productsMiniTickerData) : "NaN";
        let show_places = currency && productsData && productsData[`${currency}-USDT`] && productsData[`${currency}-USDT`].show_places ? productsData[`${currency}-USDT`].show_places : null;
        
        setCurrentPriceForCurrency(calcPrice(currentPrice, show_places));

        const goTradeData = [];
        if(Object.keys(productsMiniTickerData).filter(key => key.startsWith(`${currency ? currency : "NaN"}-`)).length > 0){
            Object.keys(productsMiniTickerData).filter(key => key.startsWith(`${currency ? currency : "NaN"}-`)).forEach((key) => {
                if(productsData[key].status_spot && productsData[key].status_spot == "active"){
                    goTradeData.push(key);
                }
            })
        }
        setGoTradeProducts(goTradeData);
    }, [currency, productsData, productsMiniTickerData]);

    return(
        <div className="section-walletlist-dialog-body">
            <div className="body-info">
                <div className="count">
                    <span>{currency && walletData && walletData[currency] && walletData[currency].count && currenciesData && currenciesData[currency] && currenciesData[currency].show_places ? formatValue1(walletData[currency].count, currenciesData[currency].show_places) : 0}</span>
                    <label>{currency && walletData && walletData[currency] && currenciesData && currenciesData["USDT"] && currenciesData["USDT"].show_places && productsMiniTickerData && getPriceForWalletCurrencies(currency, walletData, productsMiniTickerData) ? <>≈ <div>$</div>{formatValue3(getPriceForWalletCurrencies(currency, walletData, productsMiniTickerData), currenciesData["USDT"].show_places)}</> : <>≈ <div>$</div>0</>}</label>
                </div>
                <div className="price">
                    <span>Price</span>
                    <label>{`${currentPriceForCurrency} USDT`}</label>
                </div>
                <div className="transaction-history" onClick={() => {setDisplayCurrencyDialog(false);setDisplayTransactionHistoryDialog(true);}}>
                    <div>
                        <i className="fa-solid fa-receipt"></i>
                        Transaction History
                    </div>
                    <i className="fa-solid fa-chevron-right"></i>
                </div>
                {goTradeProducts.length > 0 && 
                    <div className="currencies">
                        <label className="title">Go Trade</label>
                        <div className="items">
                            {goTradeProducts.map(key => (
                                <Link className="item" key={key} to={`/trade?market=${key}`}>
                                    <div className="item-title"><label>{key.split("-")[0]}</label><span> / {key.split("-")[1]}</span></div>
                                    <div className="item-info"><label>{calcPriceForCurrencies(key)}</label><span className={productsMiniTickerData[key].percentage_difference_24h > 0 ? "success" : (productsMiniTickerData[key].percentage_difference_24h < 0 ? "danger" : undefined)}>{productsMiniTickerData && productsMiniTickerData[key] && productsMiniTickerData[key].percentage_difference_24h != null ? formatValue3(productsMiniTickerData[key].percentage_difference_24h, 2) : "NaN"}%</span></div>
                                </Link>
                            ))}
                        </div>
                    </div>
                }
            </div>
            <div className="button">
                <div>
                    {currency && walletData && walletData[currency] && walletData[currency].currency && walletData[currency].currency == "USDT" ? <button className="button-element" type="button" onClick={() => {setDisplayCurrencyDialog(false);setDisplayTransferDialog(true);}}><i className="fa-solid fa-coins"></i>Transfer</button> : ""}
                    <div>
                        <Link to="/deposit" state={{currency: currency ? currency : null}} className="button-element">
                            <i className="fa-solid fa-money-bill"></i>Deposit
                        </Link>
                        <Link to="/withdraw" state={{currency: currency ? currency : null}} className="button-element">
                            <i className="fa-solid fa-hand-holding-dollar"></i>Withdraw
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default SectionCurrencyInfoPopupContent;