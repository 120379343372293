import React, { useState, useContext } from "react";
import './pages_company.css';
import { Dialog } from 'primereact/dialog';
import SectionTransferPopupContent from './section_transfer_popup_content';
import { AuthContext } from '../components/AuthContext/AuthContext';
import { Link } from 'react-router-dom';

const Campaign50 = () => {

    const { isAuthenticated, user, signOut } = useContext(AuthContext);

    const [displayTransferDialog, setDisplayTransferDialog] = useState(false);//transfer popup görünürlüğünü tutuyor
    const [checkTerms, setCheckTerms] = useState(false);//koşulların kabul edildiğinin işaretlenmesi
    
    return(
        <div id="main" className="main">
            <Dialog header="Transfer" visible={displayTransferDialog} draggable={false} resizable={false} className="section-walletlist-dialog transfer" onHide={() => setDisplayTransferDialog(false)}>
                <SectionTransferPopupContent campaignCode="FUTURE50" />
            </Dialog>
            <div className="allsections allsections-border scrollbarhide pages-company">
                <label className="title">Step 1</label>
                <label className="text">When you click the Join the campaign button on our page, you accept the terms of the campaign and you will be directed to the transfer page to participate in the campaign.</label>
                <label className="title">Step 2</label>
                <label className="text">After clicking the Join Campaign button, your 50% bonus will be credited to your futures account with your transfer for the Future transaction.</label>
                <label className="text">You can activate your campaign with the transfer you will make by clicking the Join campaign button.</label>
                <label className="text">The campaign amount used will be added to your asset. It is defined by Kprice to increase your margin level and support your risk.</label>
            </div>
            <div className="allsections allsections-border scrollbarhide pages-company">
                <label className="title one-title">Campaign Terms and Conditions</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Kprice users can activate the Future Campaign when you make your first futures trade or if your futures trade goes into Liquidation.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>The amount transferred with the campaign is instantly transferred to your Kprice future account.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>The campaign amount provided by Kprice is not a withdrawable amount. The campaign amount used in connection with your investment will be reset to zero upon withdrawal, except for profit withdrawals, and will not be reissued.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Kprice reserves the right to disqualify users who have participated in the Kprice Future Campaign Program and to cancel their bonuses for dishonest or malicious activities. These activities include mass account registrations to receive extra bonuses, the use of "phishing" websites containing links to Related Campaigns, the posting of campaign links as advertisements in search engines, and any other activity that is illegal, abusive or fraudulent.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Kprice reserves the right to cancel or modify any Program and Future campaign Program Rules at its sole discretion.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>All users are required to strictly abide by the Kprice User agreement. Any breach of the Kprice Terms of Use will prevent the user from using the relevant campaign.</label>
                <label className="text"><i className="fa-solid fa-circle"></i>Kprice has the sole discretion to decide and determine whether a user will be entitled to any campaign and reserves the right to change these Terms and Conditions from time to time.</label>
                {isAuthenticated && <div className="contract">
                    <label>
                        <input type="checkbox" onChange={(e) => setCheckTerms(e.target.checked)} />
                        <span className="checkmark"></span>
                        <span className="text">I have read and agree the campaign terms</span>
                    </label>
                </div>}
                <div className="button">
                    <button className={isAuthenticated ? "join" : "join not-authenticated"} disabled={isAuthenticated && !checkTerms} onClick={() => {if(isAuthenticated){setDisplayTransferDialog(true);}}}>
                        {isAuthenticated ? "Join the Campaign" : <><Link to="/login">Login</Link>or<Link to="/register">Register</Link></>}
                    </button>
                </div>
            </div>
        </div>
    )

}

export default Campaign50;