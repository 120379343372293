import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { onChangeSelectedProduct, endAllCandleAndProductForProcesses } from "../Reducer/kpriceReducer";
import { TVChartContainer } from "../components/TVChartContainer/index";
import SectionTradeCryptocurrencyList from "./Trade-Sections/section_tradecryptocurrencylist";
import SectionFutureTradeBuySell from "./Future-Sections/section_futuretradebuysell";
import SectionTradeMainChartTopTitle from "./Trade-Sections/section_trademaincharttoptitle";
import SectionFutureOrderHistory from "./Future-Sections/section_futureorderhistory";
import SectionTradeCurrencyInfo from './Trade-Sections/section_tradecurrencyinfo';
import SectionLastTrades from './Trade-Sections/section_lasttrades';
import Loading from "../components/Global/Loading";
import "./page_trade.css";

const Future = () => {

  const mainArea = useRef(null);
  const productsData = useSelector((state) => state.kprice.products.data);
  const selectedProduct = useSelector((state) => state.kprice.selectedProduct);

  const dispatch = useDispatch();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const location = useLocation();
  const query = useQuery();
  const navigate = useNavigate();
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState(query.get("market"));
  const [chartReady, setChartReady] = useState(false);
  const [viewMode, setViewMode] = useState(1);
  const [productActive, setProductActive] = useState(null);

  useEffect(() => {//link değişirse
    if(productsData && Object.keys(productsData) && Object.keys(productsData).length > 0){
      const currentMarket = query.get("market");
    
      if (currentMarket && productIsExist(currentMarket) == 1) {
        setChartReady(false);
        setCurrentSelectedProduct(currentMarket);
        const newSelectedProduct = currentMarket;
        const fromPage = "future";
        dispatch(onChangeSelectedProduct({ newSelectedProduct, fromPage }));
  
        //future ana sayfaya dönünce abonelikleri sonlandır
        dispatch(endAllCandleAndProductForProcesses());
      }
      else if(currentSelectedProduct != null && productIsExist(currentMarket) != 2){
        setCurrentSelectedProduct(null);
        navigate(`/future`);
      }

      if(productIsExist(currentMarket) == 2){
        navigate(`/${currentMarket == "" ? "null" : currentMarket}`);
      }
      else if(productIsExist(currentMarket) == 1 || currentMarket == "" || currentMarket == null){
        setProductActive(true);
      }
    }
  }, [location, productsData]);
  
  useEffect(() => {
    mainArea.current.style.animation = "none";
    let tempForAnimation = mainArea.current.offsetHeight;
    mainArea.current.style.animation = "open-animation 1s";
  }, [selectedProduct]);

  const productIsExist = (product) => {//seçili product var mı?
    if (productsData && Object.keys(productsData).length > 0) {
      const productsDataKeys = Object.keys(productsData);
      if(productsDataKeys.includes(product)){
        if(productsData[product].status_future && productsData[product].status_future == "active"){
          return 1;
        }
        else{
          return 2;
        }
      }
      else if(product != null){
        return 2;
      }
      else{
        return 0;
      }
    }
    return 3;
  }

  useEffect(() => {
    const adjustWidgetSize = () => {
			if(window.innerWidth <= 500){
        setViewMode(2);
      }
      else{
        setViewMode(1);
      }
		};

		adjustWidgetSize();

		window.addEventListener('resize', adjustWidgetSize);

    return () => {//component kapanırken bu sayfa dışında gerekli olmayacak abonelikler kapatılıyor
      window.removeEventListener('resize', adjustWidgetSize);
      dispatch(endAllCandleAndProductForProcesses());
    };
  }, []);
  
  return (
    <main id="main" className="main future-sections" ref={mainArea}>
      {productActive == null ? 
        <Loading />
        : 
        (productActive && 
          (currentSelectedProduct ? 
            <>
              <div className="trade-sections">
                <Link to="/future" className="back-to">
                  <i className="fa-solid fa-angle-left"></i>
                </Link>
              </div>
              <div className="trade-sections-top">
                <div className="trade-sections-top1">
                  <div className="allsections allsections-border scrollbarhide section-mainchart">
                    <div className="section-maincharttop">
                      <SectionTradeMainChartTopTitle pageLink="future" />
                    </div>
                    <div className="section-mainchartbottom" style={chartReady ? undefined : {display : "none"}}>
                      <TVChartContainer chartReady={chartReady} setChartReady={setChartReady} viewMode={viewMode} />
                    </div>
                    <div className="section-mainchartbottom loading" style={chartReady ? {display : "none"} : undefined}>
                      <Loading />
                    </div>
                  </div>
                  <div className="bottom">
                    <SectionFutureTradeBuySell />
                    <SectionTradeCurrencyInfo currency={selectedProduct ? selectedProduct.split("-")[0] : null} />
                    <SectionLastTrades />
                  </div>
                </div>
                <div className="trade-sections-top2">
                  <SectionTradeCryptocurrencyList pageLink="future" />
                </div>
              </div>
            </> : 
            <div className="trade-sections">
              <div className="trade-sections-top">
                <div className="trade-sections-top1">
                  <SectionFutureOrderHistory />
                </div>
                <div className="trade-sections-top2">
                  <SectionTradeCryptocurrencyList pageLink="future" />
                </div>
              </div>
            </div>
          )
        )
      }
    </main>
  );
}

export default Future;