import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Global/Loading";
import { onLastTradesOpen } from "../../Reducer/kpriceReducer";
import { formatValue3 } from '../../Lib/calculationFunctions';

const SectionLastTrades = () => {

    const dispatch = useDispatch();
    
    const selectedProduct = useSelector((state) => state.kprice.selectedProduct);
    const lastTrades = useSelector((state) => state.kprice.lastTrades.data);
    const productsData = useSelector((state) => state.kprice.products.data);

    useEffect(() => {//açılışta selected product için başlangıç verileri çekiliyor
        const product = selectedProduct;
        dispatch(onLastTradesOpen({ product }));
    }, [selectedProduct]);

    return(
        <div className="allsections allsections-border section-lasttrades">
            <div className="title">
                Market Trades
            </div>
            {lastTrades.product_id == selectedProduct && lastTrades.last_trades && lastTrades.last_trades.length > 0 ? 
                <>
                    <div className="items-title">
                        <span>Price ({lastTrades.product_id.split("-")[1]})</span>
                        <span>Amount ({lastTrades.product_id.split("-")[0]})</span>
                        <span>Time</span>
                    </div>
                    <div className="items">
                        {lastTrades.last_trades.map((item, index) => (
                            <div className="item" key={index}>
                                <span className={item.m == 1 ? "success" : (item.m == 2 ? "danger" : undefined)}>{item.p != "" && item.p != null && item.p != undefined ? formatValue3(item.p, productsData && productsData[lastTrades.product_id] && productsData[lastTrades.product_id].show_places ? productsData[lastTrades.product_id].show_places : "NaN") : "NaN"}</span>
                                <span>{item.v}</span>
                                <span>{item.time}</span>
                            </div>
                        ))}
                    </div>
                </>
                : 
                <div className="loading"><Loading /></div>
            }
        </div>
    )

}

export default SectionLastTrades;